<template>
<div class="finance">
  <div class="title">{{ ourdata.customerName  }} 账单</div>
  <div class="time"> 账单期间：{{ dateFunction(ourdata.reconciliationDetailVo.reconciliationStartTime) }} 至 {{ dateFunction(ourdata.reconciliationDetailVo.reconciliationEndTime) }}</div>
  <div class="content">
    <div class="top-table">
      <table width="100%" align="center" border="1" bordercolor="#ededed" cellpadding="0" cellspacing="0" style="text-align: center;border-collapse: collapse; color: #606266;">
        <tbody>
          <tr style="line-height: 30px;">
            <td>
              需方名称：
            </td>
            <td colspan="3" style="text-align: left;">
             {{ ourdata.customerName  }}
            </td>
            <td>
              对账周期：
            </td>
            <td>
              {{ ourdata.reconciliationPeriod }}
            </td>
          </tr>
          <tr style="line-height: 30px;">
            <td>
              送货地址：
            </td>
            <td colspan="3" style="text-align: left;">
              {{ ourdata.deliveryAddress }}
            </td>
            <td>
              是否含税：
            </td>
            <td>
              {{ ourdata.isTax }}
            </td>
          </tr>
          <tr style="line-height: 30px;">
            <td>
              需方采购：
            </td>
            <td>
              {{ ourdata.checkCgUserName }}
            </td>
            <td>
              联系电话：
            </td>
            <td>
              {{ ourdata.checkCgPhone }}
            </td>
            <td>
              供方销售：
            </td>
            <td>
              {{ ourdata.saleUserName }}
            </td>
          </tr>
          <tr style="line-height: 30px;">
            <td>
              需方财务：
            </td>
            <td>
              {{ ourdata.checkCwUserName }}
            </td>
            <td>
              联系电话：
            </td>
            <td>
              {{ ourdata.checkCwPhone }}
            </td>
            <td>
              供方财务：
            </td>
            <td>
              {{ ourdata.supplierCheckCgUserName }}
            </td>
          </tr>

        </tbody>

      </table>
    </div>
    <div class="con-table">
      <table width="100%" align="center" border="1" bordercolor="#ededed" cellpadding="0" cellspacing="0" style="text-align: center;border-collapse: collapse; color: #606266;">
        <tbody>
          <tr style="line-height: 30px;">
            <td style="width: 7%;">
              序号
            </td>
            <td style="width: 10%;">
              日期
            </td>
            <td style="width: 17%;">
              服务编号
            </td>
            <td style="width: 17%;">
              服务类型
            </td>
            <td style="width: 17%;">
              批次
            </td>
            <td style="width: 17%;">
              订单/合同号
            </td>
            <td style="width: 10%;">
              本期金额
            </td>
            <td style="width: 7%;">
              备注
            </td>
          </tr>
          <!-- <tr style="line-height: 30px;">
            <td style="width: 7%;">

            </td>
            <td style="width: 10%;">
              2023/03/13
            </td>
            <td colspan="4" style="text-align: left;">
              新疆金风科技股份有限公司
            </td>
            <td style="width: 10%;">
              150,000
            </td>
            <td style="width: 7%;">
              加项
            </td>
          </tr> -->
          <tr style="line-height: 30px;" v-for="(row, rowIndex) in ourdata.reconciliationDetailVo.reconciliationDetails" :key="rowIndex">
            <td style="width: 7%;">
              {{ rowIndex + 1 }}
            </td>
            <td style="width: 10%;">
              {{ row.serviceTime }}
            </td>
            <td style="width: 17%; color: rgb(0, 121, 254);">
              {{ row.serviceNo }}
            </td>
            <td style="width: 17%;">
              {{ row.serviceType }}
            </td>
            <td style="width: 17%;">
              {{ row.batch }}
            </td>
            <td style="width: 17%;">
              {{ row.relatedOrderNo }}
            </td>
            <td style="width: 10%;">
              {{ row.amountThisPeriod |formatMoney}}
            </td>
            <td style="width: 7%;">

            </td>
          </tr>
          <!-- <tr style="line-height: 30px;">
            <td style="width: 7%;">

            </td>
            <td style="width: 10%;">
            </td>
            <td colspan="4" style="text-align: left;">
              新疆金风科技股份有限公司
            </td>
            <td style="width: 10%;">
              150,000
            </td>
            <td style="width: 7%;">
              加项
            </td>
          </tr> -->

        </tbody>

      </table>
    </div>
    <div class="bto-table">
      <table width="100%" align="center" border="1" bordercolor="#ededed" cellpadding="0" cellspacing="0" style="text-align: center;border-collapse: collapse; color: #606266;">
        <tbody>
          <tr style="line-height: 30px;">
            <td style="width: 7%;">
              序号
            </td>
            <td style="width: 10%;">
              日期
            </td>
            <td colspan="2">
              付款说明
            </td>
            <td style="width: 10%;">
              本期金额
            </td>
            <td style="width: 7%;">
              备注
            </td>
          </tr>
          <tr style="line-height: 30px;" v-for="(row, rowIndex) in ourdata.reconciliationDetailVo.paymentInfo" :key="rowIndex">
            <td style="width: 7%;">
              {{ rowIndex + 1 }}
            </td>
            <td style="width: 10%;">
              {{ row.paymentTime }}
            </td>
            <td colspan="2">
              {{ row.paymentDesc }}
            </td>
            <td style="width: 10%;">
              {{ row.paymentAmount |formatMoney}}
            </td>
            <td style="width: 7%;">

            </td>
          </tr>
          <tr style="line-height: 30px;">
            <td style="width: 7%;">

            </td>
            <td style="width: 10%;">

            </td>
            <td colspan="2" style="text-align: left;">
              本期实际已收款小计：
            </td>
            <td style="width: 10%;">
              {{ ourdata.reconciliationDetailVo.currentActualReceivedSubtotal |formatMoney}}
            </td>
            <td style="width: 7%;">
              减项
            </td>
          </tr>
          <tr style="line-height: 40px;">
            <td style="width: 7%;">

            </td>
            <td style="width: 10%;">

            </td>
            <td style="width: 17%;text-align: left;">
              尚欠货款期末余额：
            </td>
            <td style="width: 51%;">
              单位：人民币 元，大写：{{ ourdata.reconciliationDetailVo.finalDebtChinese }}
            </td>
            <td style="width: 10%;">
              {{ ourdata.reconciliationDetailVo.finalDebt |formatMoney}}
            </td>
            <td style="width: 7%;">
              合计
            </td>
          </tr>

        </tbody>

      </table>
    </div>
  </div>
</div>
</template>

<script>
import {
  getList
} from '@/api/finance'

export default {
  name: "finance",

  data() {
    return {
      contdata: [{
        date: '2023/03/13',
        code: 'BDFK20230313000101',
        type: '产品发货',
        pici: '第01批',
        codedeatil: 'BDFK20230313000101',
        picre: '150000'
      }, {
        date: '2023/03/13',
        code: 'BDFK20230313000101',
        type: '产品发货',
        pici: '第01批',
        codedeatil: 'BDFK20230313000101',
        picre: '150000'
      }, {
        date: '2023/03/13',
        code: 'BDFK20230313000101',
        type: '产品发货',
        pici: '第01批',
        codedeatil: 'BDFK20230313000101',
        picre: '150000'
      }, {
        date: '2023/03/13',
        code: 'BDFK20230313000101',
        type: '产品发货',
        pici: '第01批',
        codedeatil: 'BDFK20230313000101',
        picre: '150000'
      }, {
        date: '2023/03/13',
        code: 'BDFK20230313000101',
        type: '产品发货',
        pici: '第01批',
        codedeatil: 'BDFK20230313000101',
        picre: '150000'
      }, {
        date: '2023/03/13',
        code: 'BDFK20230313000101',
        type: '产品发货',
        pici: '第01批',
        codedeatil: 'BDFK20230313000101',
        picre: '150000'
      }, {
        date: '2023/03/13',
        code: 'BDFK20230313000101',
        type: '产品发货',
        pici: '第01批',
        codedeatil: 'BDFK20230313000101',
        picre: '150000'
      }, {
        date: '2023/03/13',
        code: 'BDFK20230313000101',
        type: '产品发货',
        pici: '第01批',
        codedeatil: 'BDFK20230313000101',
        picre: '150000'
      }, {
        date: '2023/03/13',
        code: 'BDFK20230313000101',
        type: '产品发货',
        pici: '第01批',
        codedeatil: 'BDFK20230313000101',
        picre: '150000'
      }, {
        date: '2023/03/13',
        code: 'BDFK20230313000101',
        type: '产品发货',
        pici: '第01批',
        codedeatil: 'BDFK20230313000101',
        picre: '150000'
      }],
      btodata: [{
        date: '2023/03/13',
        code: 'BDFK20230313000101',
        type: '产品发货',
        pici: '第01批',
        codedeatil: 'BDFK20230313000101',
        picre: '150000'
      }, {
        date: '2023/03/13',
        code: 'BDFK20230313000101',
        type: '产品发货',
        pici: '第01批',
        codedeatil: 'BDFK20230313000101',
        picre: '150000'
      }, {
        date: '2023/03/13',
        code: 'BDFK20230313000101',
        type: '产品发货',
        pici: '第01批',
        codedeatil: 'BDFK20230313000101',
        picre: '150000'
      }, {
        date: '2023/03/13',
        code: 'BDFK20230313000101',
        type: '产品发货',
        pici: '第01批',
        codedeatil: 'BDFK20230313000101',
        picre: '150000'
      }, {
        date: '2023/03/13',
        code: 'BDFK20230313000101',
        type: '产品发货',
        pici: '第01批',
        codedeatil: 'BDFK20230313000101',
        picre: '150000'
      }, ],
      ourdata:{}
    }
  },
  computed:{

  },
  mounted() {
    this.getdata()
  },
  methods: {
    getdata() {
      let user = this.$Cookie.get('user_info')
      const tempUserInfo = JSON.parse(user)
          console.log(tempUserInfo);
      const data = {
        customerId: tempUserInfo.customerId
      }
      getList(data).then(r => {
        this.ourdata = r.data

      })
    },
    dateFunction(time){
			var zoneDate = new Date(time).toJSON();
			var date = new Date(+new Date(zoneDate)+8*3600*1000).toISOString().replace(/T/g,' ').replace(/\.[\d]{3}Z/,'');
			return date;
		}

  },
}
</script>

<style lang="scss" scoped>
.finance {
  width: 100%;
  box-sizing: border-box;
  padding: 10px 0 60px 0;

  .title {
    width: 100%;
    text-align: center;
    font-size: 24px;
    color: #333;
    padding: 10px 0;
  }

  .time {
    font-size: 17px;
    color: #333;
  }

  .content {
    width: 100%;
    background-color: #fff;
    border: 1px solid #ededed;

    .top-table {
      width: 100%;
      background-color: #fff;

      table {
        td {
          width: 17%;
        }
      }
    }

    .con-table {
      width: 100%;
      background-color: #fff;
    }

    .bto-table {
      width: 100%;
      background-color: #fff;
    }
  }
}
</style>
