<script>
/**
 * 重写Popover 解决循环导致生成多个popover实例
 */
import { Popover } from 'element-ui'
export default {
  name: 'FilterPopover',
  extends: Popover,
  methods: {
    popBy(el) {
      this.close()
      this.doDestroy(true)
      this.$nextTick(() => {
        this.referenceElm = this.$refs.reference = el
        this.showPopper = true
        this.$emit('input', true)
      })
    },
    close() {
      this.showPopper = false
      this.$emit('input', false)
    }
  }
}
</script>
