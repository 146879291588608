
import request from '@/utils/request'

//合同列表
export function getList (data) {
  return request({
    method: 'POST',
    url: '/customer/contract/list',
    data: data
  })
}
//合同列表
export function uploadContract (data) {
  return request({
    method: 'POST',
    url: '/customer/contract/upload',
    data: data
  })
}