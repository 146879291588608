<template>
  <div class="bottom">
    <div class="comp-bottom" v-if="!type">
      <div class="info_body">
        <ul>
          <li class="font_style">产品</li>
          <li class="message" @click="skip('#profile')">产品和服务</li>
        </ul>
        <ul>
          <li class="font_style">行业</li>
          <li class="message" @click="skip('#development-solve')">行业解决方案</li>
        </ul>
        <ul>
          <li class="font_style">公司</li>
          <li class="message" @click="skip('#enterprise-culture')">公司简介</li>
        </ul>
        <ul>
          <li class="font_style">其他</li>
          <li class="message">客服热线：{{ message.webInfo.serviceHotline }}</li>
          <li class="message">服务时间：工作日 {{ message.webInfo.serviceTime}}</li>
        </ul>
      </div>
      <div class="copyright">
        <div class="copyright_line1" style="margin-bottom: 10px;">
          <span>帮助</span>
          <span>隐私</span>
          <span>条款</span>
        </div>
        <div class="copyright_line2">
          <span style="margin-right:20px;">Copyright&nbsp;©&nbsp;2024 杭州因斯科技有限公司技术支持</span>
          <span style="margin-right:20px;cursor: pointer;" @click="goJump"> 浙ICP备2024136511号</span>
        </div>
      </div>

    </div>
    <div class="comp2-bottom" v-if="type">
      <div class="copyright_line2">
        <span style="margin-right:20px;">Copyright&nbsp;©&nbsp;2024 杭州因斯科技有限公司技术支持</span>
        <span style="margin-right:20px;cursor: pointer;" @click="goJump"> 浙ICP备2024136511号</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bottomInfo",
  props: {
    message: {
      type: Object, // 指定message prop应该是一个字符串
      required: true // 设置为true表示此prop是必需的
    },
    type: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {
    skip(val) {
      this.$emit("jump", val)
    },
    goJump() {
      window.open('https://beian.miit.gov.cn', '_blank')
    },
  }
}
</script>

<style lang="scss" scoped>
.bottom {
  width: 100%;
  background: #f9fbff;
  .comp-bottom {
    width: 85%;
    margin: 0 auto;
    padding: 30px 30px 30px 50px;
    .info_body {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      ul {
        list-style-type: none;
        width: 33%;
        color: #606270;
      }
    }
    .font_style {
      font-weight: bold;
      font-size: 19px;
      margin: 50px 0 20px 0;
      color: #606266;
    }
    .message {
      font-size: 15px;
      margin: 0 0 10px 0;
      color: #909399;
      cursor: pointer;
    }
    .copyright {
      color: rgba(0, 0, 0, 0.45);
      font-size: 15px;
      text-align: center;
      margin: 50px 0 0 0;
      span {
        margin: 30px;
      }
      .copyright_line2 {
        width: 100%;
        // padding: 0 15% 0 0;

        text-align: center;
      }
    }
  }
}
.comp2-bottom {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
