
import request from '@/utils/request'

//商城列表
export function getInfo(code) {
  return request({
    method: 'GEt',
    url: `/mall/getInfo/${code}`
  })
}
// 查询商城信息通过ID
export function getMallById(mallInfoId) {
  return request({
    method: 'GEt',
    url: `/mall/getMallById?mallInfoId=` + mallInfoId
  })
}
// 查询商城信息通过ID
export function getToken(userId) {
  return request({
    method: 'GEt',
    url: `/mall/getToken?userId=${userId}`
  })
}



//商城列表
export function getProduct(data) {
  return request({
    method: 'POST',
    url: '/product/hot',
    data: data
  })
}
//分销商品牌
export function getBrand(data) {
  return request({
    method: 'POST',
    url: '/product/brands',
    data: data
  })
}
//商品分类
export function getCategory(data) {
  return request({
    method: 'POST',
    url: '/product/category/list',
    data: data
  })
}

export function getNoticedeatil(data) {
  return request({
    method: 'get',
    url: `/notice/notice/${data}`,

  })
}
// export function getNoticedeatil (data) {
//   const datas = {
//     noticeId: data
//   }
//   return request({
//     method: 'post',
//     url: '/notice/notice',
//     data: datas

//   })
// }
export function getNotice(data) {
  return request({
    method: 'GET',
    url: '/notice/notice/queryAll',

  })
}

//职位
export function getDept(type) {
  return request({
    method: 'GEt',
    url: `/sys/dict/data/type/${type}`
  })
}
