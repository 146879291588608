<template>
  <div class="filter-box">
    <div v-if="type === 'text'">
      <el-input
        v-model="keyword"
        size="mini"
        prefix-icon="el-input__icon el-icon-search"
        placeholder="搜索关键词"
        @change="onValueChange"
      />
    </div>
    <div v-if="type === 'select'">
      <el-input
        v-model="keyword"
        size="mini"
        prefix-icon="el-input__icon el-icon-search"
        type="text"
        placeholder="搜索关键词"
      />
      <div class="select-box">
        <el-checkbox-group v-model="arrayValue" @change="onValueChange">
          <el-checkbox
            v-for="(item, index) in optionList"
            :key="index"
            :label="item"
          >{{ item }}
          </el-checkbox>
        </el-checkbox-group>
        <div v-if="optionList.length == 0" class="no-data">暂无数据</div>
      </div>
    </div>
    <div v-if="['date', 'datetime'].includes(type)">
      <el-date-picker
        v-model="arrayValue"
        :type="type === 'date' ? 'daterange' : 'datetimerange'"
        :value-format="type === 'date' ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm:ss'"
        range-separator="至"
        :start-placeholder="type === 'date' ? '开始日期' : '开始时间'"
        :end-placeholder="type === 'date' ? '结束日期' : '结束时间'"
        @change="onValueChange"
      />
    </div>
    <div v-if="type === 'progress'">
      <el-progress
        :text-inside="true"
        :stroke-width="15"
        :percentage="50"
        status="exception"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'FilterContent',
  props: {
    type: {
      type: String,
      default: undefined,
      require: true
    },
    options: {
      type: Array,
      default: () => []
    },
    defaultOptions: {
      type: Array,
      default: () => []
    },
    data: {
      type: [Array, String],
      default: () => []
    }
  },
  data() {
    return {
      keyword: '',
      arrayValue: [],
      loading: false,
      optionsLoading: false
    }
  },
  computed: {
    isArrayValue() {
      return (
        ['select', 'date', 'datetime'].includes(this.type)
      )
    },
    optionList() {
      let result = []
      result = this.options.filter(
        (item) => {
          if (item) {
            return String(item).toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
          }
        }
      )
      return result
    }
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        if (this.isArrayValue) {
          this.keyword = ''
          this.arrayValue = this.data || []
        } else {
          this.keyword = this.data
        }
      }
    }
    // defaultOptions: {
    //   immediate: true,
    //   deep: true
    // }
  },
  methods: {
    onValueChange() {
      let result = this.keyword
      if (this.isArrayValue) {
        result = this.arrayValue
      }
      this.$emit('valueChange', result)
    }
  }
}
</script>
<style lang="scss" scoped>
  .filter-box{
    width: 150px;
  }
 .select-box {
  max-height: 280px;
  margin-top: 10px;
  overflow: auto;
  ::v-deep & .el-checkbox {
    display: block;
    width: 100%;
    &.is-checked{
      background: #f2f2f2;
    }
    .el-checkbox__label{
      font-size: 12px;
    }
    .el-checkbox__input{
      display: none;
    }
  }
}
.no-data {
  text-align: "center";
  color: "#909399";
}
</style>
