<template>
  <div class="detailjump">
    <!--面包屑-->
    <div class="bread">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/website/shophome/shop' }">首页</el-breadcrumb-item>
        <!--       <el-breadcrumb-item>-->
        <!--         <router-link :to="{ path: '/website/shophome/productscreening' }">产品筛选</router-link>-->
        <!--       </el-breadcrumb-item>-->
        <el-breadcrumb-item @click.native="goScreen">
          产品筛选
        </el-breadcrumb-item>
        <el-breadcrumb-item>产品详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="detail_info">
      <!--   大小图切换展示-->
      <div class="detail_img">
        <div class="bigimg" v-if="detailProducut.img1Url">
          <img style="width: 100%;height: 100%" :src="detailProducut.img1Url" alt="big">
        </div>
        <div class="bigimg" v-if="!detailProducut.img1Url">
          <div style="width: 100%;height: 100%;border: 1px solid #c1bdbd;"></div>
          <!-- <img style="width: 100%;height: 100%" src="" alt="暂无更多图片"> -->
        </div>
        <div class="smalimg">
          <div v-if="detailProducut.img1Url">
            <img @click="showsmall1(1)" style="width: 100%;height: 100%" :src="detailProducut.img1Url" alt="big">
          </div>
          <div v-if="!detailProducut.img1Url">
            <div style="width: 100%;height: 100%;border: 1px solid #c1bdbd;"></div>
            <!-- <img @click="showsmall1(2)" style="width: 100%;height: 100%" src="" alt="暂无更多图片"> -->
          </div>
          <div v-if="detailProducut.img2Url">
            <img @click="showsmall1(3)" style="width: 100%;height: 100%" :src="detailProducut.img2Url" alt="big">
          </div>
          <div v-if="!detailProducut.img2Url">
            <div style="width: 100%;height: 100%;border: 1px solid #c1bdbd;"></div>
            <!-- <img @click="showsmall1(2)" style="width: 100%;height: 100%" src="" alt="暂无更多图片"> -->
          </div>
          <div v-if="detailProducut.img3Url">
            <img @click="showsmall1(4)" style="width: 100%;height: 100%" :src="detailProducut.img3Url" alt="big">
          </div>
          <div v-if="!detailProducut.img3Url">
            <div style="width: 100%;height: 100%;border: 1px solid #c1bdbd;"></div>
            <!-- <img @click="showsmall1(2)" style="width: 100%;height: 100%" src=""  alt="暂无更多图片"> -->
          </div>
        </div>
      </div>
      <!-- 右侧信息展示-->
      <div class="detail_right">
        <!-- 右上       -->
        <div class="right_price">
          <div class="name_price">
            <h2 style="margin: 0 0 10px 0">{{detailProducut.name}}</h2>
            <p><span style="display: inline-block;width: 13%;">促销价：</span><span>{{detailProducut.costPrice}}</span></p>
            <p><span
                style="display: inline-block;width: 13%;">市场价：</span><span>￥<del>{{detailProducut.recommendPrice}}个</del></span>
            </p>
          </div>
          <div class="name_logo">
            <img v-if="detailProducut.logoUrl" :src="detailProducut.logoUrl" alt="logo">
            <div v-else style="width: 100%;height: 100%;border:1px solid #c1bdbd"></div>
          </div>
        </div>
        <!-- 右中       -->
        <div class="center_info">
          <p><span>品牌：</span><span>{{detailProducut.brand}}</span></p>
          <p><span>订货号：</span><span>{{detailProducut.orderName}}</span></p>
          <p><span>品类：</span><span>{{detailProducut.productCategory}}</span></p>
          <p><span>型号：</span><span>{{detailProducut.marque}}</span></p>
          <p><span>系列：</span><span>{{detailProducut.series}}</span></p>
          <!--          <p><span>包装规格：</span><span>{{detailProducut.packingSpecification[0]}}个/盒,{{detailProducut.packingSpecification[1]}}盒/箱</span></p>-->
          <div style="width: 100%;padding: 10px 0"><span
              style="display: inline-block;width: 11%;">规格描述：</span><span>{{detailProducut.specification}}</span></div>
        </div>
        <!-- 右下       -->
        <div class="center_info">
          <p v-if="detailProducut.deliveryTime === 1"><span>货期：</span><span
              style="display: inline-block;width: 15%;background: #2DD5A5;text-align: center;color: white;font-size: 15px">现货</span>
          </p>
          <p v-else><span>货期：</span><span
              style="background: #FD5366;padding: 5px 20px;color: white;display: inline-block;width: 15%;">期货</span></p>
          <p><span>库存：</span><span>{{detailProducut.inventory}}个</span></p>
          <div style="width: 100%;"><span
              style="display: inline-block;width: 11%;">配送地址:</span><span>{{detailProducut.address}}</span></div>
          <div style="width: 100%;padding: 5px 0"><span
              style="display: inline-block;width: 11%;">支付方式:</span><span>{{detailProducut.payType === 1 ? '银行对公转账' : '微信支付'}}</span>
          </div>
          <div style="width: 100%;"><span
              style="display: inline-block;width: 11%;">质保政策:</span><span>质保{{detailProducut.WarrantyPolicy}}个月</span>
          </div>
        </div>
      </div>
    </div>
    <div class="totaljoincar">
      <el-input-number v-model="shopTotal" @change="handleChange" :min="0" :max="100" label="描述文字"></el-input-number>
      <el-button style="width: 15%;margin-left: 20px;font-size: 16px" type="primary" @click="joinShopCar" round>加购物车<i
          class="el-icon-shopping-cart-2"></i></el-button>
    </div>

  </div>
</template>

<script>
import bottomInfo from "@/views/job/bottomInfo.vue";
import { conditionProductList, joinCar } from "@/api/productscreening";


export default {
  name: "detailjump",
  components: { bottomInfo },
  data() {
    return {
      //  大图地址,默认展示第一张，正式服对接
      bigPhotoUrl: require('../../assets/images/01u24251.svg'),
      detailProducut: {
        name: '施耐德按钮指示灯XB2',
        promotionPrice: 9999,
        marketPrice: 2.99,
        logoUrl: require('../../assets/images/p6.png'),
        trademark: '西门子',
        orderName: 'XB2BA31C',
        category: '低压配电',
        modelNo: 'XB2BA31C=AB2BZ01C+ZB2BA3C',
        series: 'XB2',
        packingSpecification: ['10', '15'],
        specification: '平头按钮、绿色、22mm、1NO、自复型、金属、不带灯',
        deliveryTime: 1,
        inventory: 300,
        address: '新疆/马尔扎哈',
        payType: 1,
        WarrantyPolicy: 12
      },
      shopTotal: 0
    }
  },
  created() {
  },
  mounted() {
    this.getProduct()
  },
  methods: {
    getProduct() {
      let routeParam = JSON.parse(this.$route.query.product)
      let queryParams = {
        distributorId: this.$Cookie.get('distributorId'),
        distributorCompanyId: this.$Cookie.get('distributorCompanyId'),
        marque: routeParam.marque
      }
      if (this.$Cookie.get('accessToken')) {
        let user = this.$Cookie.get('user_info')
        const tempUserInfo = JSON.parse(user)
        queryParams.customerId = tempUserInfo.customerId
      }
      // console.log(queryParams)
      conditionProductList(queryParams).then((res) => {
        if (res.code === 200) {
          this.detailProducut = res.data
        }
      })
    },
    showsmall1(param) {
      if (param === 1) {
        this.bigPhotoUrl = this.detailProducut.img1Url
      } else if (param === 3) {
        this.bigPhotoUrl = this.detailProducut.img2Url
      } else if (param === 4) {
        this.bigPhotoUrl = this.detailProducut.img3Url
      } else if (param === 2) {
        this.$message.warning('暂无更多图片')
      }

    },
    // showsmall2(){
    //   this.bigPhotoUrl = require('../../assets/images/01u24252.svg')
    // },
    // showsmall3(){
    //   this.bigPhotoUrl = require('../../assets/images/01u24253.svg')
    // },
    // 商品数量事件
    handleChange() { },
    /**
     * Date:2024/03/11
     * Author:wt
     * Function:加购物车
     * @param:无
     */
    joinShopCar() {
      if (this.shopTotal <= 0) {
        this.$message.error('请先添加产品数量')
      } else {
        const loginCheck = this.$Cookie.get('userName')
      if (loginCheck) {
        let user = this.$Cookie.get('user_info')
        const tempUserInfo = JSON.parse(user)
        const queryParams = {
          shoppingCartProducts: [{
            marque: this.detailProducut.marque,
            quantity: this.shopTotal
          }],
          userId: tempUserInfo.customerId
        }
        joinCar(queryParams).then((res) => {
          if (res.code === 200) {
            this.$message.success('加入购物车成功')
            this.$parent.jumpShopping()
          }
        })
      }else {
        this.$message.warning('请先登录') 
      }
    }
    },
    goScreen() {
      let routeParam = JSON.parse(this.$route.query.product)
      let parObj = JSON.stringify(routeParam)
      this.$router.push({
        path: '/website/shophome/productscreening',
        query: {
          product: parObj
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.detailjump {
  .bread {
    padding: 20px 0;
  }
  .detail_info {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    .detail_img {
      width: 27%;
      height: 580px;
      .bigimg {
        width: 100%;
        height: 76%;
      }
      .smalimg {
        width: 100%;
        height: 72%;
        margin-top: 5px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        div {
          width: 30%;
          height: 30%;
          cursor: pointer;
        }
      }
    }
    .detail_right {
      margin-left: 5px;
      width: 73%;
      height: 580px;
      .right_price {
        width: 100%;
        height: 25%;
        display: flex;
        flex-wrap: nowrap;
        border-bottom: 1px solid #ebeef5;
        .name_price {
          width: 80%;
          height: 100%;
          background: white;
          padding: 5px 15px;
          display: flex;
          margin: 0 0;
          flex-direction: column; /* 垂直方向上按顺序排列 */
          p {
            color: #666666;
            margin: 10px 0;
          }
        }
        .name_logo {
          width: 20%;
          height: 100%;
          background: white;
          img {
            width: 75%;
          }
        }
      }
      .center_info {
        width: 100%;
        height: 37%;
        display: flex;
        flex-wrap: wrap;
        padding: 10px 15px;
        color: #666666;
        border-bottom: 1px solid #ebeef5;
        background: white;
        p {
          width: 50%;
          padding: 15px 0;
          span:nth-child(1) {
            display: inline-block;
            width: 22%;
          }
          span:nth-child(2) {
            display: inline-block;
            width: 78%;
          }
        }
      }
    }
  }
  .totaljoincar {
    width: 100%;
    height: 90px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }
}
</style>
