<template>
  <div class="body-con">
    <filter-table ref="filterTable" :loading="loading" :data="dataList" row-key="quotationId" row-height="50px"
      :columns="columnList" @rowClick="handleRowClick">
      <template #switchOrder="scope">
        <el-button class="m-btn" type="primary" @click.stop="switchToOrder(scope.row)"> 转为订单 </el-button>
      </template>
      <template #action="scope">
        <el-button-group class="actions">
          <el-button type="primary" title="下载报价单" @click.stop="downloadQutation(scope.row)">
            <svg-icon icon-class="download" />
          </el-button>
          <el-button type="danger" title="删除报价单">
            <svg-icon icon-class="delete" @click.stop="deleteQutation(scope.row)" />
          </el-button>
        </el-button-group>
      </template>
      <el-table-column type="expand" width="1">
        <template scope="scope">
          <expand-table :data="scope.row.expandList" :columns="columnList2" :loading="scope.row.loading" :bottom="true">
            <template #deliveryTerm="mscope">
              <span class="sText"
                :class="scope.row.expandList[mscope.row.$index].deliveryTerm === '现货' ? 'green' : ''">{{ scope.row.expandList[mscope.row.$index].deliveryTerm }}</span>
            </template>
            <template #deliveryTermDifference="mscope">
              <span class="sText"
                :class="scope.row.expandList[mscope.row.$index].deliveryTermDifference === '现货' || scope.row.expandList[mscope.row.$index].deliveryTermDifference >= 0 ? 'green' : 'red'">{{ scope.row.expandList[mscope.row.$index].deliveryTermDifference }}</span>
            </template>
            <template #inventoryDifference="mscope">
              <span class="sNum"
                :class="scope.row.expandList[mscope.row.$index].inventoryDifference > 0 ? 'green' : 'red'">{{ scope.row.expandList[mscope.row.$index].inventoryDifference }}</span>
            </template>
            <template #bottom>
              <div class="left">
                <span class="total-name">总计</span>
              </div>
              <div class="right">
                <span class="num">{{ total(scope.row.expandList).totalNum }}</span>
                <span class="total">{{ total(scope.row.expandList).totalPrice }}</span>
              </div>
            </template>
          </expand-table>
        </template>
      </el-table-column>
    </filter-table>
  </div>
</template>

<script>
import FilterTable from '@/components/FilterTable/index.vue'
import ExpandTable from '@/components/ExpandTable/index.vue'
import { queryQuotationRecordList, queryOrderProductDetails, deleteQuotation } from '@/api/orderManage'
import { EXECUTE_STATUS } from '@/utils/const'
import { getUserInfo } from "@/utils/auth"
import * as _ from 'lodash'
const columnList = [
  {
    label: '报价单号',
    prop: 'quotationNo',
    type: 'select',
    filterable: true
  },
  {
    label: '订单类型',
    prop: 'orderType',
    type: 'select',
    filterable: true
  },
  {
    label: '客户名称',
    prop: 'customerName',
    type: 'select',
    filterable: true
  },
  {
    label: '提交人',
    prop: 'submitterName',
    type: 'select',
    filterable: true
  },
  {
    label: '负责销售',
    prop: 'saleUserName',
    type: 'select',
    filterable: true
  },
  {
    label: '发货模式',
    prop: 'executionMode'
  },
  {
    label: '需货时间',
    prop: 'deliveryDateTime',
    type: 'select',
    filterable: true
  },
  {
    label: '订单金额',
    prop: 'orderAmount',
    align: 'right',
    sortable: true
  },
  {
    label: '转为订单',
    slotName: 'switchOrder'
  },
  {
    label: '操作',
    slotName: 'action'
  }
]
const columnList2 = [
  {
    label: '产品型号',
    prop: 'productMarque',
    align: 'left'
  },
  {
    label: '品牌',
    prop: 'brand'
  },
  {
    label: '品类',
    prop: 'productCategory'
  },
  {
    label: '参考货期',
    slotName: 'deliveryTerm'
  },
  {
    label: '货期差额',
    slotName: 'deliveryTermDifference'
  },
  {
    label: '单价',
    prop: 'benchmarkPrice',
    align: 'right'
  },
  {
    label: '数量',
    prop: 'productNum',
    width: '120px'
  },
  {
    label: '库存差额',
    slotName: 'inventoryDifference'
  },
  {
    label: '总价',
    prop: 'productAmount',
    width: '120px',
    align: 'right'
  },
  {
    label: '执行进度',
    prop: 'executeStatus'
  }
]
export default {
  components: {
    FilterTable,
    ExpandTable
  },
  data() {
    return {
      loading: false,
      loading2: false,
      dataList: [],
      columnList,
      columnList2,
      addVisible: false,
      infoVisible: false,
      formInfo: {},
      form: {
        signFilePath: '', // 签收单文件地址
        signTime: '', // 签收时间
        outboundNo: '' // 出库编码
      },
      views: [],
      commonParams: {
        distributorCompanyId: getUserInfo().distributorCompanyId || 0,
        distributorId: getUserInfo().distributorId,
        // userId:getUserInfo().userId,
      }
    }
  },
  computed: {
    total() {
      return function (list) {
        let totalNum = 0
        let totalPrice = 0
        list.map(item => {
          if (item.productNum) {
            totalNum = totalNum + item.productNum
          }
          totalPrice = totalPrice + item.productAmount
        })
        totalPrice = totalPrice.toFixed(2)
        return { totalNum, totalPrice }
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.dataList = []
      // this.loading = true
      queryQuotationRecordList(this.commonParams).then(response => {
        console.log(response);
        // this.dataList = response.data.map(item => {
        //   item.loading = true
        //   item.expandList = []
        //   item.orderType = item.orderType === 1 ? '客户订单' : item.orderType === 2 ? '项目订单' : '次品订单'
        //   item.executionMode = item.executionMode === 1 ? '手动排发' : '整单自发'
        //   return item
        // })
        // this.loading = false
        // this.$nextTick(() => {
        //   this.$refs.filterTable.packUp()
        // })
      })
    },
    handleRowClick(row) {
      if (!row.expandList.length) {
        this.dataList[row.index].loading = true
        queryOrderProductDetails({ ...this.commonParams, orderNo: row.quotationNo, orderType: row.orderType === '客户订单' ? 1 : row.orderType === '项目订单' ? 2 : 3 }).then(response => {
          this.dataList[row.index].loading = false
          this.dataList[row.index].expandList = response.data.map(d => {
            return {
              ...d,
              executeStatus: _.get(_.find(EXECUTE_STATUS, { value: d.executeStatus }), 'text', ''),
              deliveryTermDifference: d.deliveryTerm === 0 ? '现货' : Math.ceil((new Date(row.deliveryDate) - new Date()) / (1000 * 3600 * 24)) - d.deliveryTerm,
              deliveryTerm: d.deliveryTerm === 0 ? '现货' : d.deliveryTerm,
              inventoryDifference: d.openInventoryNum - d.productNum
            }
          })
        })
      }
    },
    handleShowInfo(row) {
      this.formInfo = row
      this.infoVisible = true
    },
    handleDialog(row) {
      this.form = {
        signFilePath: '', // 签收单文件地址
        signTime: '', // 签收时间
        outboundNo: row.outboundNo // 出库编码
      }
      this.addVisible = true
      this.$nextTick(() => {
        this.$refs['form'].clearValidate()
      })
    },
    // 取消按钮
    cancel() {
      this.addVisible = false
      this.infoVisible = false
    },
    downloadQutation(row) {
      window.open(row.priceFilePath, ' _blank')
    },
    deleteQutation(row) {
      this.$modal.confirm('是否确认删除报价单号' + row.quotationNo).then(() => {
        deleteQuotation({
          ...this.commonParams,
          quotationNo: row.quotationNo
        })
          .then(() => {
            this.getList()
            this.$modal.msgSuccess('删除报价单成功')
          })
          .catch(() => { })
      })
    },
    switchToOrder(row) {
      this.$parent.handleSwitchTargetTab('1', row)
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .y-table {
  .footer {
    margin: 10px auto;
    position: relative;
  }
}
.boder-bottom {
  position: relative;
  padding-bottom: 30px;
  margin-bottom: 30px;
  &::after {
    content: "";
    position: absolute;
    left: -40px;
    right: -40px;
    bottom: 0;
    border-bottom: 1px solid #f2f2f2;
  }
}
.total {
  margin-right: 135px;
}
.num {
  margin-right: 200px;
}
</style>
