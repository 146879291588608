<template>
  <div class="body-con" v-if="orderShow">
    <filter-table ref="filterTable" :height="`calc(100vh - ${230}px)`" :loading="loading" :data="dataList"
      :columns="columnList" row-key="orderRecordId" row-height="50px" @rowClick="handleRowClick">
      <template #orderAmount="scope">
        {{scope.row.orderAmount | formatMoney}}
      </template>
      <template #action="scope">
        <el-button-group class="actions">
          <el-button type="danger" title="取消订单" 
          :disabled="scope.row.orderType=== '历史订单' || scope.row.cancelType == 0 || scope.row.orderStatus === '订单取消' || scope.row.orderStatus === '订单修改'"
            @click.stop="handleDelete(scope.row)">
            <img src="@/assets/images/del.svg" />
          </el-button>
          <el-button type="primary" title="编辑"
          :disabled="scope.row.orderType=== '历史订单' || scope.row.cancelType == 0 || scope.row.orderStatus === '订单取消' || scope.row.orderStatus === '订单修改'"
            @click.stop="handleEdit(scope.row)">
            <img src="@/assets/images/bianji.svg" />
          </el-button>
          <el-button type="primary" title="订单详情" 
          :disabled="scope.row.orderType == '历史订单'"
            @click.stop="handleApplyInvoice(scope.row)">
            <img src="@/assets/images/xiangq.svg" />
          </el-button>
          <el-button type="danger" title="退货" :disabled="scope.row.orderType=== '历史订单' || scope.row.orderStatus === '订单取消' || scope.row.orderStatus === '订单修改' || scope.row.orderStatus === '排发完成' "
            @click.stop="handleOrderReturn(scope.row)">
            <img src="@/assets/images/retrun.svg" />
          </el-button>
        </el-button-group>
      </template>
      <el-table-column type="expand" width="1">
        <template scope="scope">
          <expand-table :data="scope.row.expandList" :columns="columnList2" :loading="scope.row.loading" :bottom="true"
            :selectable="true" @selection-change="selectionChange">
            <template #benchmarkPrice="mscope">
              {{mscope.row.benchmarkPrice | formatMoney}}
            </template>
            <template #productAmount="mscope">
              {{mscope.row.productAmount | formatMoney}}
            </template>
            <template #productNum="mscope">
              <el-input-number v-model="scope.row.expandList[mscope.row.$index].productNum" class="step" size="mini"
                :max="scope.row.expandList[mscope.row.$index].productNum"
                :disabled="scope.row.expandList[mscope.row.$index].productNum === 0 || scope.row.expandList[mscope.row.$index].executeStatus !== '已出库' && scope.row.expandList[mscope.row.$index].executeStatus !== '已签收'"/>
            </template>
            <template #bottom>
              <div class="left">
                <span class="total-name">总计</span>
              </div>
              <div class="right">
                <span class="num"  style="margin-right: -10px">{{ total(scope.row.expandList).totalNum | formatMoney}}</span>
                <span class="total"  style="margin-right: 130px;text-align: right">{{ total(scope.row.expandList).totalPrice | formatMoney}}</span>
              </div>
            </template>
          </expand-table>
          <div class="footer">
            <el-button type="primary" class="m-btn" @click="returnGoodsBtnClick(scope.row)">选中退货</el-button>
            <el-button class="m-btn" @click="cleanData">取消</el-button>
          </div>
        </template>
      </el-table-column>
    </filter-table>
    <el-dialog title="退货确认" :visible.sync="dialogVisible" :before-close="cancel" width="600px" append-to-body center>
      <div class="applyBox">
        <el-form ref="form" label-width="100px" label-position="left" :model="form" :rules="rules">
          <el-form-item label="退货仓库：" prop="warehouseName">
            <el-col :span="14" style="margin-left: 5px">
              <el-autocomplete v-model="form.warehouseName" class="inline-input" :fetch-suggestions="querySearch"
                placeholder="" suffix-icon="el-icon-search" @select="handleSelect" />
            </el-col>
          </el-form-item>
          <el-form-item label="退货原因：" prop="returnReason">
            <el-col :span="14" style="margin-left: 5px">
              <el-input v-model="form.returnReason" type="textarea" class="inline-input w300" />
            </el-col>
          </el-form-item>
          <el-form-item label="退货清单：" />
          <filter-table :loading="loading" :data="willReturnProducts" :columns="columnList3" :bottom="true" height="300">
            <template #bottom>
              <div class="left">
                <span class="total-name">总计</span>
              </div>
              <div class="right">
                <span class="num">{{ totalReturn().totalNum | formatMoney}}</span>
                <span class="total">{{ totalReturn().totalPrice | formatMoney}}</span>
              </div>
            </template>
          </filter-table>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer text-center">
        <el-button type="primary" @click="returnGoodsConfirm">确认退货</el-button>
      </div>
    </el-dialog>

  </div>
  <router-view v-else></router-view>
</template>
<script>
import FilterTable from '@/components/FilterTable/index.vue'
import ExpandTable from '@/components/ExpandTable/index.vue'
import { queryOrderRecord, queryOrderProductDetails, cancelOrder, applyForInvoice, queryWarehouseList, addReturnOrder } from '@/api/orderManage'
import { getUserInfo } from '@/utils/auth'
import { ORDER_STATUS, EXECUTE_STATUS,ORDER_TYPES } from '@/utils/const'
import * as _ from 'lodash'
import Login from '../login.vue'
const columnList = [
  {
    label: '订单号',
    prop: 'orderNo',
    type: 'select',
    width:'200',  
    filterable: true
  },
  {
    label: '订单类型',
    prop: 'orderType',
    type: 'select',
    filterable: true
  },
  {
    label: '客户名称',
    prop: 'customerName',
    type: 'select',
    filterable: true,
    width:"250"
  },
  {
    label: '提交人',
    prop: 'submitterName',
    type: 'select',
    filterable: true
  },
  {
    label: '负责销售',
    prop: 'saleUserName',
    type: 'select',
    filterable: true
  },
  {
    label: '发货模式',
    prop: 'executionMode'
  },
  {
    label: '需货时间',
    prop: 'deliveryDateTime',
    filterable: true
  },
  {
    label: '订单状态',
    prop: 'orderStatus',
    type: 'select',
    filterable: true
  },
  {
    label: '处理人',
    prop: 'updateBy'
  },
  {
    label: '订单金额',
    slotName: 'orderAmount',
    sortable: true,
    align: "right",
    width:'120px'
  },
  {
    label: '操作',
    slotName: 'action',
    width: "120px"
  }
]
const columnList2 = [
  {
    label: '产品型号',
    prop: 'productMarque',
    align: 'left'
  },
  {
    label: '单位',
    prop: 'unit'
  },
  {
    label: '品牌',
    prop: 'brand'
  },
  {
    label: '品类',
    prop: 'productCategory'
  },
  {
    label: '采购单号',
    prop: 'orderNo'
  },
  {
    label: '单价',
    slotName: 'benchmarkPrice',
    align: "right"
  },
  {
    label: '数量',
    slotName: 'productNum',
    width: '120px',
    align: "center"
  },
  {
    label: '总价',
    slotName: 'productAmount',
    width: '120px',
    align: "right"
  },
  {
    label: '执行进度',
    prop: 'executeStatus',
    width: '120px',
  }
]
const columnList3 = [
  {
    label: '产品型号',
    prop: 'productMarque',
    align: 'left'
  },
  {
    label: '单价',
    prop: 'productAmount'
  },
  {
    label: '退货数量',
    prop: 'quantityReturned'
  },
  {
    label: '退回总价',
    prop: 'totalRefundAmount'
  }
]
export default {
  name: "ordermanage",
  components: {
    FilterTable,
    ExpandTable
  },
  data() {
    return {
      loading: false,
      orderShow: true,
      dataList: [],
      columnList: columnList,
      columnList2: columnList2,
      columnList3: columnList3,
      commonParams: {
        distributorCompanyId: getUserInfo().distributorCompanyId || 0,
        distributorId: getUserInfo().distributorId
      },
      dialogVisible: false,
      form: {
        warehouseName: '',
        returnReason: ''
      },
      searchKey: '',
      // 表单校验
      rules: {
        warehouseName: [
          { required: true, message: '请选择仓库', trigger: ['change'] }
        ],
        returnReason: [
          { required: true, message: '请输入退货原因', trigger: ['change'] }
        ]
      },
      restaurants: [],
      selectProductItems: [],
      willReturnProducts: [],
      selectedItem: null
    }
  },
  watch: {
    '$route'(to, from) {
      if (to.name === 'ordermanage') {
        this.orderShow = true
      }
    }
  },
  computed: {
    total() {
      return function (list) {
        let totalNum = 0
        let totalPrice = 0
        list.map(item => {
          if (item.productNum) {
            totalNum = totalNum + item.productNum
          }
          if (item.productAmount) {
            totalPrice = totalPrice + item.productAmount
          }
        })
        return { totalNum, totalPrice }
      }
    },
    totalReturn() {
      return () => {
        let totalNum = 0
        let totalPrice = 0
        this.willReturnProducts.map(item => {
          if (item.quantityReturned) {
            totalNum = totalNum + item.quantityReturned
          }
          totalPrice = totalPrice + item.totalRefundAmount
        })
        return { totalNum, totalPrice }
      }
    }
  },
  mounted() {
    this.getList()
    this.getReturnWarehouseList()
  },
  methods: {
    getList() {
      let user = this.$Cookie.get('user_info')
      const tempUserInfo = JSON.parse(user)
      this.commonParams =
      {
        distributorCompanyId: tempUserInfo.distributorCompanyId || 0,
        distributorId: tempUserInfo.distributorId,
        customerId: tempUserInfo.customerId
      }

      this.loading = true
      queryOrderRecord(this.commonParams).then(response => {
        this.dataList = response.data.map(item => {
          item.loading = true
          item.expandList = []
          item.orderType =  _.get(_.find(ORDER_TYPES, { value: item.orderType }), 'text', '')
          item.executionMode = item.executionMode === 1 ? '手动排发' : '整单自发'
          item.orderStatus = _.get(_.find(ORDER_STATUS, { value: item.orderStatus }), 'text', '')
          return item
        })
        this.loading = false
        this.$nextTick(() => {
          // this.$refs.filterTable.packUp()
        })
      })
    },
    getReturnWarehouseList() {
      this.loading = true
      queryWarehouseList(this.commonParams).then(response => {
        this.restaurants = response.data.map(item => {
          item.value = item.warehouseName
          return item
        })
        this.loading = false
      })
    },
    querySearch(queryString, cb) {
      console.log(queryString)
      var restaurants = this.restaurants
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter(queryString) {
      return restaurant => {
        return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      }
    },
    handleRowClick(row, type) {
      if (!row.expandList.length) {
        this.dataList[row.index].loading = true
        queryOrderProductDetails({ ...this.commonParams, orderNo: row.orderNo }).then(response => {
          this.dataList[row.index].loading = false
          this.dataList[row.index].expandList = response.data.map(d => {
            return {
              ...d,
              executeStatus: _.get(_.find(EXECUTE_STATUS, { value: d.executeStatus }), 'text', ''),
              disabledSelect: (d.executeStatus !== 9 && d.executeStatus !== 10)
            }
          })


          if (type == "jump") {
            let parObj = JSON.stringify(this.dataList[row.index])
            this.$router.push({
              path: "/website/ordermanage/modifyordermanage",
              query: {
                product: parObj
              }
            })
          }
        })
      }
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$modal.confirm('是否确认取消订单号' + row.orderNo).then(() => {
        cancelOrder({
          ...this.commonParams,
          orderId: row.orderId
        })
          .then((res) => {
            if(res.data){
              this.getList()
              this.$modal.msgSuccess('取消成功')
            }
          })
          .catch(() => { })
      })
    },
    handleEdit(row) {
      // this.$parent.handleNewTab('修改订单', row)
      this.orderShow = false
      let parObj = JSON.stringify(row)
      if (!row?.expandList?.length > 0) {
        this.handleRowClick(row, "jump")
      } else {
        this.$router.push({
          path: "/website/ordermanage/modifyordermanage",
          query: {
            product: parObj
          }
        })
      }

    },
    handleApplyInvoice(row) {
      this.orderShow = false
      let parObj = JSON.stringify(row)
      this.$router.push({
        path: "/website/ordermanage/detailordermanage",
        query: {
          product: parObj
        }
      })
    },
    handleSelect(item) {
      this.selectedItem = item
    },
    handleOrderReturn(row) {
      // console.log(row); 
      // this.handleRowClick(row)
      this.$refs?.filterTable?.handleRowClick(row)
      // if (!row.expandList.length) {
      //   this.dataList[row.index].loading = true
      //   queryOrderProductDetails({ ...this.commonParams, orderNo: row.orderNo }).then(response => {
      //     this.dataList[row.index].loading = false
      //     this.dataList[row.index].expandList = response.data.map(d => {
      //       return {
      //         ...d,
      //         executeStatus: _.get(_.find(EXECUTE_STATUS, { value: d.executeStatus }), 'text', '')
      //       }
      //     })
      //     this.willReturnProducts= response.data.map(d => {
      //       return {
      //         ...d,
      //     quantityReturned: d.productNum,
      //     totalRefundAmount: (d.productNum * d.benchmarkPrice).toFixed(2)
      //       }
      //     })
      //   })
      // }
    },
    returnGoodsBtnClick(row) {
      if (_.isEqual(_.size(this.selectProductItems), 0)) {
        this.$message.warning({
          title: '警告',
          message: '请先选择需要退货的产品'
        })
        return
      }
      this.currentOrder = row
      this.willReturnProducts = this.selectProductItems.map((d) => {
        return {
          ...d,
          quantityReturned: d.productNum,
          totalRefundAmount: d.productNum * d.benchmarkPrice
        }
      })
      this.dialogVisible = true
    },
    cleanData() {
      this.selectProductItems = []
      this.willReturnProducts = []
    },
    returnGoodsConfirm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          const params = {
            ...this.currentOrder,
            warehouseId: this.selectedItem.warehouseId,
            returnReason: this.form.returnReason,
            executionMode: this.currentOrder.executionMode === '手动排发' ? 1 : 2,
            orderType: _.get(_.find(ORDER_TYPES, { text: this.currentOrder.orderType }), 'value', ''),
            orderStatus: _.get(_.find(ORDER_STATUS, { 'text': this.currentOrder.orderStatus }), 'value', 0),
            returnOrderGoodsDetailDTOList: this.willReturnProducts.map((d) => {
              return {
                ...d,
                returnOrderGoodsDetailId:d.orderGoodsDetailId,
                executeStatus: _.get(_.find(EXECUTE_STATUS, { text: d.executeStatus }), 'value', 0)
              }
            }),
            ...this.commonParams
          }
          this.loading = true
          addReturnOrder(params).then(() => {
            // console.log(123);
            //      this.$message({
            //   message: '退货成功',
            //   type: 'success'
            // });
            this.$modal.msgSuccess('退货成功')
            this.cancel()
            this.loading = false
            this.getList()
          })
        }
      })
    },
    selectionChange(e) {
      this.selectProductItems = e
    },
    cancel() {
      this.dialogVisible = false
      this.form = {}
      this.willReturnProducts = []
    }
  }
}
</script>
<style lang="scss" scoped>
// .total {
//   margin-right: 136px !important;
// }
.right {
  // margin-right: 185px !important;
}
// ::v-deep .y-table .el-table .el-table__expanded-cell::before {
//   display: none !important;
// }
::v-deep .applyBox .y-table {
  margin: 0 !important;
}
::v-deep .y-table .bottom .total-name {
  padding-left: 60px;
}
::v-deep .y-table .bottom .right {
  // margin-right: -3px !important;
}
.step .el-input-number__increase {
  height: 21px;
}
.step .el-input-number__decrease {
  height: 21px;
}
.el-input-number--mini {
  width: 90px;
}
.step {
  line-height: 20px;
  ::v-deep .el-input__inner {
    height: 20px;
    line-height: 20px;
  }
}
</style>
