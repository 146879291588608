<template>
  <div class="error">
    当前网站已锁定
  </div>
</template>

<script>
import {
  getInfo,
} from '@/utils/api'
export default {
  name: "error",
  components: {},
  data() {
    return {}
  },
  created() {
    var that = this;
    that.getList()
  },
  methods: {
    getList() {
      const lastPart = this.$Cookie.get('distributor')
      getInfo(lastPart).then(res => {
        if (res.data?.data?.systemConfig?.webSwitch === 0) {
        } else {
          this.$router.push("/web")
        }
      })
    }
  },
  computed: {},
}
</script>
<style  lang="scss" scoped>
.error {
  width: 100%;
  height: 100%;
  margin: 200px auto;
  text-align: center;
  font-size: 30px;
}
</style>