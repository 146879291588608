
import request from '@/utils/request'

//财务报表
export function getList (data) {
  return request({
    method: 'GET',
    url: '/receivables/manage/viewReconciliationOrder',
    params: data
  })
}