<template>
<div class="product_screen">
  <div v-if="productScreenShow">
    <!--面包屑-->
    <div class="bread">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/website/shophome/shop' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item >公告详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="noticedetail">
      <div class="detail">
      <div class="detail_title">{{ form.noticeTitle }}</div>
      <div class="detail_tit">{{ form.publishDeptName.replace(/\,/g,'、') }}</div>
      <div class="detail_content" v-html="form.noticeContent"></div>
      <div class="zhang">
        <p class="company">杭州***电气自动化有限公司</p>
        <p class="time">{{ form.createTime }}</p>
        <!-- <p class="time">{{ parseTime(new Date(),'{y}年{m}月{d}日') }}</p> -->
      </div>
    </div>
    </div>
    <!--  表格 -->
    <div>
    </div>
  </div>
<!--  三级路由出口-->
 <router-view  v-else/>
</div>
</template>

<script>
import bottomInfo from "@/views/job/bottomInfo.vue";
import {getNoticedeatil} from "@/api/mallindex";

export default {
  name: "noticedetail",
  components:{bottomInfo},
  data() {
    return {
      productScreenShow:true,
      form:{}
    }
  },
  created(){
  },
  mounted(){
    this.getList()
  },
  methods:{
    getList() {
      let routeParam = JSON.parse(this.$route.query.product)
      getNoticedeatil(routeParam.noticeId).then(res=>{
        this.form = res.data
      })
    },
  }
}
</script>

<style scoped lang="scss">
.product_screen{
  width: 100%;
  .bread{
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
  }
  .noticedetail{
  width: 900px;
  height: 1020px;
  background-color: #fff;
  margin: 10px auto;
  padding: 40px;
  .detail{
  position: relative;
  line-height: 35px;
  min-height: 820px;
  padding-bottom: 200px;
  .detail_title{
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    color: #000;
  }
  .detail_tit{
    text-align: center;
  }
  .detail_content{
    text-align: left;
  }
}
.zhang{
  position: absolute;
  right: 0;
  bottom: 20px;
  text-align: center;
  white-space: nowrap;
  font-size: 16px;
  font-weight: bold;
  color: #000;
}
}
}
</style>
