<template>
<div class="modify-contract">
  <div class="body-table">
      <div class="search-box">
        <div class="search-top">
          <div class="leball">
            <div>订单类型:</div>
            <div style="margin-left: 20px;width: 260px;">客户订单_新疆XX科技股份有限公司</div>
          </div>
          <div class="leball">
            <div> 财务规则:</div>
            <el-input v-model="form.financialRegulations" style="width: 260px; margin-top: -10px; margin-left: 20px;"></el-input>
          </div>
          <div class="leball">
            <div>发货模式:</div>
            <div style="width: 260px; text-align: center; margin-left: 20px;">
              <el-switch style="margin-left: 25px" v-model="form.deliveryMode" active-color="#13ce66" inactive-color="#ff4949"></el-switch><span v-if="form.deliveryMode">整单自发</span>
              <span v-if="!form.deliveryMode">手动排发</span>
            </div>
          </div>
        </div>
        <div class="search-top" style="margin-top: 20px;">
          <div class="leball">
            <div>签约方式:</div>
            <el-input v-model="form.wayOfSigning" style="width: 260px; margin-top: -10px; margin-left: 20px;"></el-input>
          </div>
          <div class="leball">
            <div>需货时间:</div>
            <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd" v-model="form.date1" style="width: 260px; margin-top: -10px; margin-left: 20px;"></el-date-picker>
          </div>
          <div class="leball">
            <div>组合替代:</div>
            <div style="width: 260px; text-align: center; margin-left: 20px;">
              <el-switch v-model="form.substitution" active-color="#13ce66" inactive-color="#ff4949"></el-switch><span v-if="form.substitution">允许</span>
              <span v-if="!form.substitution">不允许</span>
            </div>
          </div>
        </div>
      </div>
      <div class="table-wrap">
        <div class="left">
          <el-input v-model="searchName" class="textarea" type="textarea" placeholder="例如：TM251MESE 10" />
          <div class="search-btn">
            <div class="btn" @click="search">
              <img src="@/assets/images/search2.svg" alt="">
              <span class="text">查询</span>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="y-table">
            <el-table v-loading="loading" :data="[{},{},{}]" :row-style="{ height: '25px' }">
              <el-table-column label="序号" align="center" type="index" width="50" />
              <el-table-column label="产品型号" align="center" prop="productMarque" />
              <el-table-column label="品牌" align="center" prop="brand" />
              <el-table-column label="品类" align="center" prop="productCategory" />
              <el-table-column label="参考货期" align="center" />
              <el-table-column label="货期差额" align="center" />
              <el-table-column label="单价" align="center" />
              <el-table-column label="数量" align="center">
                <template slot-scope="scope">
                  <el-input-number v-model="scope.row.number" class="step" size="mini" />
                </template>
              </el-table-column>
              <el-table-column label="库存差额" align="center">
                <template slot-scope="scope">
                  <div class="flex"><span class="sNum green">{{ scope.row.number }}</span></div>
                </template>
              </el-table-column>
              <el-table-column label="总价" align="center" />
              <el-table-column label="操作">
                <template slot-scope="scope">
                <span class="actions">
                  <el-button style="width: 30px;height: 20px" type="danger" icon="el-icon-delete" @click.stop="handleDelete(scope.row)" plain></el-button>
                </span>
                </template>
              </el-table-column>
            </el-table>
            <div class="bottom">
              <span class="total-name">总计</span>
              <span class="num">148</span>
              <span class="total">794,500</span>
            </div>
          </div>

        </div>

      </div>
      <div style="display: flex; justify-content: space-between;">
        <div class="tip">批量输入型号和数量，用空格隔开，也可从Excel中复制粘贴</div>
<!--        <div style="margin: 5px; color: #666666;">-->
<!--          <el-switch v-model="delivery" active-color="#13ce66" inactive-color="#ff4949"></el-switch><span v-if="!delivery">不</span>允许组合型号替代-->
<!--        </div>-->
      </div>

      <div class="footer">
        <el-button type="primary" @click="commit">确认修改</el-button>
        <el-button plain @click="commit">取消</el-button>
      </div>

    </div>
</div>
</template>

  
<script>
export default {
  name: "modifycontract",
  data() {
    return {
      loading: false,
      delivery: true,
      form: {
        financialRegulations:'银行承兑6个月',
        deliveryMode:true,
        wayOfSigning:'购销合同-通用购销-标准购销合同',
        substitution:true
      },
      dataList: [],
      searchName: '',
      searchData: {}
    }
  },
  mounted() {},
  methods: {
    getList() {
      // const params = {
      //   distributorCompanyId: getUserInfo().distributorCompanyId || 0,
      //   distributorId: getUserInfo().distributorId
      // }
      // productTransferPrice(this.searchData).then(response => {
      //   this.dataList = response.data || []
      //   this.loading = false
      // })
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    },
    handleSelect(item) {
      console.log(item)
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      // const roleIds = row.roleId || this.ids
      // this.$modal.confirm('是否确认删除角色编号为"' + roleIds + '"的数据项？').then(function() {
      //   return delRole(roleIds)
      // }).then(() => {
      //   this.getList()
      //   this.$modal.msgSuccess('删除成功')
      // }).catch(() => {})
    },
    changeWarehouse($event, type) {
      if (type === 'out') {
        this.searchData.warehouseId = $event[0]
        this.searchData.warehouseAreaId = $event[1]
      }
    },
    search() {
      const arr = []
      const arrtext = this.searchName.split('\n') // 以转行符切割文本字符串
      arrtext.forEach((item, index) => {
        if (item) {
          const regex = /\t|\s/
          const cols = item.split(regex)
          console.log('列：' + cols[0] + '  ' + cols[1])
          arr.push({
            productMarque: cols[0],
            productNum: cols[1]
          })
        }
      })
      this.searchData.productTradeList = arr
      this.getList()
    },
    commit() {},
    rest() {}
  }
}
</script>

  
<style lang="scss">
.modify-contract {
  .search-top {
    display: flex;
    justify-content: space-between;

    .leball {
      display: flex;
    }
  }
  .right{
    position: relative;
  }
  .el-table .cell {
    padding: 3px 0;
  }

  .el-input--mini .el-input__inner {
    height: 21px;
  }

  .step .el-input-number__increase {
    height: 21px;
  }
  .step .el-input-number__decrease{
    height: 21px;
  }
  .el-input-number--mini{
    width: 90px;
  }

  .actions {
    padding-left: 13px;
  }
  .bottom{
    position: absolute;
    bottom: 0;
  }
}
</style>
