import axios from "axios"
/**
 * 表格时间格式化
 */
export function formatDate(cellValue) {
  // eslint-disable-next-line eqeqeq
  if (cellValue == null || cellValue == '') return ''
  var date = new Date(cellValue)
  var year = date.getFullYear()
  var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
}


export function downLoadFile(url, back) {
  axios({
    url,
    method: 'get',
    responseType: 'blob'
  }).then((res) => {
    const blob = new Blob([res.data], { type: 'application/pdf' })
    const href = URL.createObjectURL(blob) // 创建新的URL表示指定的blob对象
    const a = document.createElement('a') // 创建a标签
    a.style.display = 'none'
    a.href = href // 指定下载链接
    a.download = `${Date.now()}.pdf` // 指定下载文件名
    a.click() // 触发下载
    URL.revokeObjectURL(a.href) // 释放URL对象
  })
}