<template>
  <div class="after_sales">
    <div class="service">
      <div class="table_head">
        <img class="logo" src="../../assets/images/u2135.png" alt="logo">
        <p style="line-height: 10px;text-align: center">服务工作单</p>
      </div>
      <div style="display: flex;flex-wrap: nowrap;align-items: center;">
        <div style="display: inline-block;width:55%;">
          <p style="font-size: 16px;">工单编号：{{queryData.mallServiceNo}}</p>
        </div>
        <div style="display: flex;flex-wrap: nowrap;justify-content: right;align-items: center;width: 45%;">
          <span style="font-size: 15px !important;">服务时间：</span>
          <el-date-picker style="width: 55%;" @change="changeTime" value-format="yyyy-MM-dd" v-model="queryData.serTime"
            type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </div>
      </div>
      <!--  表格-->
      <div class="inputDeep">
        <table width="100%" border="1" cellspacing="0" cellpadding="0" align="center">
          <tr>
            <th>服务主题：</th>
            <td colspan="3">
              <el-input v-model="queryData.serviceTheme"></el-input>
            </td>
            <th>提报时间：</th>
            <td>
              <el-input v-model="queryData.submissionTime" :disabled="true"></el-input>
            </td>
          </tr>
          <tr>
            <th>客户名称：</th>
            <td>
              <el-input v-model="queryData.serviceCustomerName"></el-input>
            </td>
            <th>客户编号：</th>
            <td>
              <el-input v-model="queryData.serviceCustomerId" :disabled="true"></el-input>
            </td>
            <th>服务类型：</th>
            <td>
              <el-select style="width: 250px" v-model="queryData.serviceType" placeholder="请选择"
                @change="changeServeType">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </td>
          </tr>
          <tr>
            <th>服务地点：</th>
            <td style="display: flex;align-items: center">
              <img src="../../assets/images/zb.jpg" alt="">
              <el-cascader size="large" :options="optionsAddress" v-model="queryData.serviceAddressTemp"
                @change="addressChange">
              </el-cascader>
            </td>
            <td colspan="2">
              <!--            <el-input v-model=""></el-input>-->
            </td>
            <th>涉及品类：</th>
            <td>
              <el-input v-model="queryData.productsInvolved"></el-input>
            </td>
          </tr>
          <tr>
            <th>联系人：</th>
            <td>
              <el-input v-model="queryData.contacts"></el-input>
            </td>
            <th>联系电话：</th>
            <td>
              <el-input v-model="queryData.telephone"></el-input>
            </td>
            <th>负责销售：</th>
            <td>
              <el-input v-model="queryData.saleUserName" :disabled="true"></el-input>
            </td>
          </tr>
        </table>
        <div class="ser_question">
          <div class="questabhead">
            <p style="color: #7F7F7F;font-size: 15px;font-weight:bold;">问题描述：</p>
          </div>
          <div class="ques_textarea">
            <el-input id="inputarea" type="textarea" placeholder="请输入内容" v-model="queryData.requirement">
            </el-input>
          </div>
          <div style="width: 19%;text-align: center;margin-top: 111px">
            <el-checkbox v-model="paone" @change="outlaw=!paone"
              style="color: #7F7F7F;font-size: 15px;font-weight:bold;">保内</el-checkbox>
            <el-checkbox v-model="outlaw" @change="paone=!outlaw"
              style="color: #7F7F7F;font-size: 15px;font-weight:bold;">保外</el-checkbox>
          </div>
        </div>
        <div class="ser_desc">
          <div class="questabheadser">
            <p style="color: #7F7F7F;font-size: 15px;font-weight:bold;">服务内容：</p>
          </div>
          <div class="ser_table">
            <el-table border :header-cell-style="{ textAlign: 'center' }" :data="tableData" style="width: 100%;">
              <el-table-column label="序号" align="center" width="65">
                <slot slot-scope="scope">
                  <span>{{scope.$index + 1}}</span>
                </slot>
              </el-table-column>
              <el-table-column prop="desc" label="描述" align="center" min-width="2">
              </el-table-column>
              <el-table-column prop="price" label="单价" align="center" min-width="1">
              </el-table-column>
              <el-table-column prop="count" align="center" label="数量" min-width="1">
              </el-table-column>
              <el-table-column prop="total" align="center" label="总价" min-width="1">
              </el-table-column>
            </el-table>
            <div style="width: 100%;height: 35px;display: flex;flex-wrap: nowrap;text-align: center;line-height: 35px">
              <p style="width: 858px;height: 35px;border-right: 1px solid #D7D7D7;font-size: 15px">总计</p>
              <p style="width: 259px;font-size: 15px;">111</p>
            </div>
          </div>
        </div>
        <div class="table_bottom">
          <div style="width: 16%;border-right: 2px solid #D7D7D7;">
            <p style="color: #7F7F7F;font-size: 15px;font-weight:bold;text-align: center;line-height:200px">客户确认：</p>
          </div>
          <div style="width: 50%;border-right: 2px solid #D7D7D7;">
            <p style="color: #7F7F7F;font-size: 16px;padding: 40px 0 0 50px;margin-bottom: 40px">请对本次服务作出评价：</p>
            <div style="margin-left: 80px">
              <el-checkbox v-model="greatSatisfaction" @change="greatSatis">非常满意</el-checkbox>
              <el-checkbox v-model="satisfaction" @change="handleSatisfaction">很满意</el-checkbox>
              <el-checkbox v-model="beBasicallySatisfied" @change="beBasicallySatis">基本满意</el-checkbox>
              <el-checkbox v-model="noSatisfaction" @change="handleGreatSatis">不满意</el-checkbox>
              <el-checkbox v-model="veryNoSatisfaction" @change="handlVeryNo">非常不满意</el-checkbox>
            </div>
          </div>
          <div style="width: 30%;">
            <div style="display: block;width: 100%;height: 121px;line-height: 191px;margin-left: 30px;">
              <p>客户签字：</p>
            </div>
            <div class="cus_date">
              <span>年</span>
              <span>月</span>
              <span>日</span>
            </div>
          </div>
        </div>
      </div>
      <div style="padding: 30px;text-align: center">
        <el-button style="width: 15%;" type="primary" @click="submit">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { provinceAndCityData, codeToText } from 'element-china-area-data'
import { submitOrder, workOrderNo } from "@/api/aftersales";
export default {
  name: "aftersales",
  data() {
    return {
      // 地区组件
      optionsAddress: provinceAndCityData,
      queryData: {
        mallServiceNo: null,
        serTime: [],
        serviceCustomerId: '',
        serviceTheme: '',
        submissionTime: this.getCurrentFormattedTime(),
        serviceCustomerName: '',
        serviceType: '',
        productsInvolved: '',
        serviceAddressTemp: [],
        serviceAddress: '',
        contacts: '',
        telephone: '',
        saleUserName: '',
        requirement: ''
      },
      options: [
        { value: 1, label: '售前交流' },
        { value: 2, label: '样机调试' },
        { value: 3, label: '交付调试' },
        { value: 4, label: '售后服务' },
      ],
      paone: false,
      outlaw: false,
      // 服务内容数据
      tableData: [
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
      ],
      greatSatisfaction: false,
      satisfaction: false,
      beBasicallySatisfied: false,
      noSatisfaction: false,
      veryNoSatisfaction: false,
      cookieInfo: {},
    }
  },
  mounted() {
    this.getSaleName()
  },
  methods: {
    /**
     * Date:2024/3/21
     * Author:luhua
     * Function:服务类型改变+工单编号获取
     * @param:无
     */
    changeServeType() {
      workOrderNo({ serviceType: this.queryData.serviceType }).then(res => {
        if (res.code === 200) {
          this.queryData.mallServiceNo = res.data
        }
      })
    },
    /**
     * Date:2024/2/28
     * Author:luhua
     * Function:服务时间选择
     * @param:无
     */
    changeTime() {
      // console.log(this.queryData.serTime)
      this.queryData.serviceStartTime = this.queryData.serTime[0]
      this.queryData.serviceEndTime = this.queryData.serTime[1]
      // const user =  this.$Cookie.get('user_info')
      // const userInfo = JSON.parse(user)
      // console.log(userInfo)
    },
    /**
     * Date:2024/2/28
     * Author:luhua
     * Function:提交售后服务
     * @param:无
     */
    submit() {
      delete this.queryData.serTime;
      delete this.queryData.serviceAddressTemp;
      console.log(this.queryData)
      submitOrder(this.queryData).then(res => {
        if (res.code === 200) {
          this.$message.success('提交成功')
          this.queryData = {
            mallServiceNo: null,
            serTime: [],
            serviceCustomerId: '',
            serviceTheme: '',
            submissionTime: this.getCurrentFormattedTime(),
            serviceCustomerName: '',
            serviceType: '',
            productsInvolved: '',
            serviceAddressTemp: [],
            serviceAddress: '',
            contacts: '',
            telephone: '',
            saleUserName: '',
            requirement: ''
          }
        }
      })
    },
    addressChange(arr) {
      // console.log(this.queryData);
      let addressName = `'${codeToText[arr[0]]}/${codeToText[arr[1]]}'`
      this.queryData.serviceAddress = addressName
    },
    greatSatis() {
      if (this.greatSatisfaction === true) {
        this.satisfaction = false
        this.beBasicallySatisfied = false
        this.noSatisfaction = false
        this.veryNoSatisfaction = false
      }
    },
    handleSatisfaction() {
      if (this.satisfaction === true) {
        this.greatSatisfaction = false
        this.beBasicallySatisfied = false
        this.noSatisfaction = false
        this.veryNoSatisfaction = false
      }
    },
    beBasicallySatis() {
      if (this.beBasicallySatisfied === true) {
        this.greatSatisfaction = false
        this.satisfaction = false
        this.noSatisfaction = false
        this.veryNoSatisfaction = false
      }
    },
    handleGreatSatis() {
      if (this.noSatisfaction === true) {
        this.greatSatisfaction = false
        this.satisfaction = false
        this.beBasicallySatisfied = false
        this.veryNoSatisfaction = false
      }
    },
    handlVeryNo() {
      if (this.veryNoSatisfaction === true) {
        this.greatSatisfaction = false
        this.satisfaction = false
        this.beBasicallySatisfied = false
        this.noSatisfaction = false
      }
    },
    /**
     * Date:2024/3/21
     * Author:luhua
     * Function:自动生成提报时间
     * @param:无
     */
    getCurrentFormattedTime() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始，需要加 1
      const day = String(currentDate.getDate()).padStart(2, '0');
      const hours = String(currentDate.getHours()).padStart(2, '0');
      const minutes = String(currentDate.getMinutes()).padStart(2, '0');
      const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}`;
      return formattedTime;
    },
    /**
     * Date:2024/3/21
     * Author:luhua
     * Function:获取负责销售
     * @param:无
     */
    getSaleName() {
      let user = this.$Cookie.get('user_info')
      const tempUserInfo = JSON.parse(user)
      this.queryData.saleUserName = tempUserInfo.saleUserName ? tempUserInfo.saleUserName : ''
      this.queryData.serviceCustomerId = tempUserInfo.customerId ? tempUserInfo.customerId : ''
      this.queryData.distributorId = this.$Cookie.get('distributorId')
      this.queryData.distributorCompanyId = tempUserInfo.distributorCompanyId
    },
  },
}
</script>

<style  lang="scss" scoped>
.after_sales {
  table,
  th,
  td {
    border: 1px solid #d7d7d7;
  }
  th {
    color: #7f7f7f;
    font-size: 15px;
    width: 14%;
  }
  td {
    width: 14%;
  }
  margin: 10px 0 0 0;
  width: 100%;
  .service {
    width: 100%;
    padding: 100px;
    background: white;
    margin: 0 auto;
    .table_head {
      width: 100%;
      height: 111px;
      position: relative;
      font-size: 27px;
      font-weight: bold;
      .logo {
        width: 85px;
        height: 85px;
        position: absolute;
        left: 180px;
        top: -25px;
      }
    }
    .inputDeep {
      margin-top: 10px;
      width: 100%;
    }
    ::v-deep .inputDeep .el-input__inner {
      border: 0;
    }
    ::v-deep #inputarea {
      min-height: 227px !important;
      border: none;
      resize: none;
    }
    ::v-deep .el-table .el-table__cell {
      padding: 6px;
    }

    ::v-deep .el-input--large {
      width: 175px;
    }
    .el-cascader {
      line-height: 39px;
    }

    .ser_question {
      width: 100%;
      height: 230px;
      border: 2px solid #d7d7d7;
      border-top: none;
      border-bottom: none;
      display: flex;
      flex-wrap: nowrap;
      .questabhead {
        width: 16%;
        height: 230px;
        border-right: 2px solid #d7d7d7;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .ques_textarea {
        display: inline-block;
        width: 64.4%;
        height: 230px;
        border-right: 2px solid #d7d7d7;
      }
    }
    .ser_desc {
      width: 100%;
      height: 436px;
      border: 2px solid #d7d7d7;
      display: flex;
      flex-wrap: nowrap;
      .questabheadser {
        width: 16%;
        height: 432px;
        border-right: 2px solid #d7d7d7;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .ser_table {
        width: 84%;
        height: 496px;
      }
    }
    .table_bottom {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      height: 200px;
      border: 2px solid #d7d7d7;
      border-top: none;
      .cus_date {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        width: 100%;
        height: 70px;
        margin-left: 30px;
        line-height: 80px;
        span {
          display: inline-block;
          width: 60px;
        }
      }
    }
  }
}
</style>
