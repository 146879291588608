<template>
  <div>
    <div style="width: 1360px;height: 174px;background: #F5F7FA;margin: 0 auto">
      <div class="website" :style="changeHover(navBackground)">
        <!--  顶部导航栏 -->
        <div :style="{ 'background': navBackground }" class="ysnavbar">
          <div class="logo-outer-box">
            <div class="logo-outer nav">
              <div class="logo-inside" @click="showFlag = 1">
                <img alt="logo"
                  :src="systemConfig?.systemLogoUrl ? systemConfig?.systemLogoUrl :require('../../assets/images/logo.jpg')">
              </div>
            </div>
            <p class="company-name" @click="showFlag = 1">{{mallConfig.mallName}}</p>
          </div>
          <div class="nav-help">
            <ul class="nav-menu">
              <li style="margin-left: 5px" @click="callOur">
                <img alt="" src="../../assets/images/u2671.svg" style="width: 16px;">
              </li>
              <li @click="handleTip" style="position: relative">
                <span class="redMain" v-if="redMain"></span>
                <img alt="" src="../../assets/images/u2664.svg" style="width: 14px;">
              </li>
              <li @click="">
                <img alt="" src="../../assets/images/u2661.svg" style="width: 17px;">
              </li>

            </ul>
            <div class="custom-dropdown">
              <span style="color: white;cursor: pointer;margin-right: 10px;" @click="login('register')"> 注册</span>
              <span style="color: white;cursor: pointer" @click="login('login')">{{ loginName }}</span>
              <el-dropdown>
                <span class="el-dropdown-link" style="color: white;">
                  <span class="userimg"><img :src="avatarImg ? avatarImg : require('@/assets/images/user.png')"
                      alt="user" style="width: 100%;height: 100%;border-radius: 50%;"></span><i
                    class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item style="color: black;" @click.native="loginout">
                    <img alt="" src="../../assets/images/u199.svg"
                      style="width: 13px; height: 13px; margin-right: 5px;" />
                    安全退出
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <div class="messageBox" v-if="tipFlag">
            <div class="message_title">
              <div style="font-size: 14px;font-weight: bold">{{ diaTitle }}</div>
              <div v-if="messageData && messageData.length > 0"><img :src="avatarTip" alt="" @click="diaRighthandle">
              </div>
            </div>
            <div v-if="messageData.length == 0" style="text-align: center; line-height: 200px;">
              暂无新消息
            </div>
            <div v-for="(item, key) in messageData" :key="key">

              <div class="message_table" v-if="messFlag">
                <div class="mess_left" @click="handleMessage(item, $event)">
                  <div>{{ item.title }}&nbsp;&nbsp;<i class="redPoint" v-show="item.dataStatus==1"></i></div>
                  <div>{{ getShortenedText(item.content) }}</div>
                </div>
                <div class="mess_right">
                  {{ getTimeAgo(item.time) }}
                </div>
              </div>

            </div>
            <div v-if="!messFlag">
              <div style="padding: 10px">
                <div style="display:flex;flex-wrap: nowrap">
                  <div style="font-size: 15px;width: 85%;">{{ infoDetail.title }}</div>
                  <div style="font-size: 10px;width: 14%;">{{ getTimeAgo(infoDetail.time) }}</div>
                </div>
                <div>{{ infoDetail.content }}</div>
              </div>
            </div>
          </div>
        </div>
        <!--  body-->
        <div v-if="showFlag === 1" class="ys-body">
          <!--  轮播器-->
          <div class="body_search">
            <!-- <div class="logosearch">search</div> -->
            <div class="logosearch">
              <div class="left">
                <img alt=""
                  :src="mallConfig?.mallLogoUrl ? mallConfig?.mallLogoUrl : require('../../assets/logohome.png')"
                  style="width: 200px; height: 53px;">
              </div>
              <div class="right">
                <div class="r-input">
                  <el-input v-model="form.roleName" class="input" placeholder="搜索" show-word-limit>
                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                  </el-input>
                  <!--                   <el-autocomplete v-model="state" class="el-input" :fetch-suggestions="querySearchAsync"-->
                  <!--                    placeholder="请输入内容" @select="handleSelect"></el-autocomplete>-->
                  <div class="in-text" style="display: flex; width: 50px" @click="handleSelect">搜索</div>
                </div>
                <div class="r-btn">
                  <el-badge :value="shoppingNum" class="item" style="width: 100%;">
                    <el-button icon="el-icon-shopping-cart-2" style="width: 100%; height: 100%;" type="primary"
                      @click="jumpShopping('jump')">购物车
                    </el-button>
                  </el-badge>

                </div>
              </div>
            </div>
            <div class="hambur">
              <div :style="{ 'background': navBackground }" class="nav_tip">
                <p>
                  <img alt="tip" src="../../assets/images/u469.svg">
                  全部商品分类
                </p>
              </div>
              <div class="nav_hambur">
                <el-tabs v-if="tabShow" v-model="activeTab" :stretch="true" @tab-click="handleTabs">
                  <el-tab-pane name="shophome">
                    <!--                <i class="el-icon-s-home"></i>-->
                    <span slot="label"><i class="el-icon-s-home"></i>首页</span>
                    <!--                首页-->
                  </el-tab-pane>
                  <el-tab-pane name="quickorder">
                    <span slot="label"><i class="iconfont icon-kuaisudingdan"></i> 快速下单</span>
                  </el-tab-pane>
                  <el-tab-pane name="ordermanage">
                    <span slot="label"><i class="el-icon-document-copy"></i> 订单列表</span>
                  </el-tab-pane>
                  <el-tab-pane name="contract">
                    <span slot="label"><i class="iconfont icon-dingdanyonghu"></i> 合同用印</span>

                  </el-tab-pane>
                  <el-tab-pane name="finance">
                    <span slot="label"><i class="iconfont icon-caiwuguanli"></i> 财务对账</span>

                  </el-tab-pane>
                  <!--              <el-tab-pane>-->
                  <!--                <router-link to="/website/aftersales" slot="label">-->
                  <!--                  <i><img src="../../assets/images/u2629.svg" alt="" /></i> 售后服务-->
                  <!--                </router-link>-->
                  <!--&lt;!&ndash;                售后服务&ndash;&gt;-->
                  <!--              </el-tab-pane>-->
                  <el-tab-pane name="aftersales">
                    <span slot="label"><i class="iconfont icon-dingdan"></i> 售后服务</span>
                  </el-tab-pane>
                  <el-tab-pane name="chat">
                    <span slot="label"><i class="el-icon-s-custom"></i> 咨询留言</span>
                  </el-tab-pane>
                </el-tabs>

                <div v-if="!tabShow" class="no-login">
                  <el-tabs v-if="!tabShow" v-model="activeTab" :stretch="true" @tab-click="handleTabs">
                    <el-tab-pane name="shophome">
                      <!--                <i class="el-icon-s-home"></i>-->
                      <span slot="label"><i class="el-icon-s-home"></i>首页</span>
                      <!--                首页-->
                    </el-tab-pane>
                  </el-tabs>
                  <!-- <img src="../../assets/images/home.png" alt="">首页 -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--二级路由出口-->
    <div style="width: 100%;background: #f5f7fa; min-height: calc(100vh - 180px);">
      <div style="width:1360px;margin: 0 auto;">
        <div style="width: 1360px;margin: 0 auto;">
          <router-view :key="routerKey" />

        </div>
      </div>
    </div>
    <bottom-info v-if="activeTab === 'shophome'"></bottom-info>
    <!--    通用底部-->
  </div>
</template>

<script>
import bottomInfo from "@/views/job/bottomInfo.vue";
import { getProduct, getUserInfo, userLoginOut, getnotice, updatered, updateAllred } from "@/utils/api";
import { getInfo, getMallById, getToken } from "@/api/mallindex"
import { getProductList, shoppingCar } from "@/api/productscreening";
function extractAccessTokenValue(cookies) {
  let accessTokenCookie = cookies.find(cookie => cookie.startsWith('accessToken='))
  if (accessTokenCookie) {
    let accessToken = accessTokenCookie.split('=')[1]
    return accessToken
  } else {
    return null
  }
}

export default {
  name: "website",
  components: { bottomInfo, },
  data() {
    return {
      routerKey: 123,
      //  背景色
      navBackground: '#0079FE',
      // 登录名切换
      loginName: '登录',
      tipFlag: false,
      avatarImg: require('@/assets/images/user.png'),
      showFlag: 1,
      lastTabName: null,
      form: { roleName: '' },
      activeTab: 'shophome',
      //   搜索框上搜索
      restaurants: [],
      state: '',
      timeout: null,
      diaTitle: '消息通知',
      messageData: [],
      messFlag: true,
      infoDetail: {},
      avatarTip: require('@/assets/images/u40053.png'),
      tabShow: false,
      datalist: {},
      systemConfig: {
        systemLogoUrl: ''
      },
      mallConfig: {
        mallName: "分销商城",
        mallLogoUrl: require("../../assets/images/logo.jpg")
      }, // 商城基本信息
      token: null,
      shoppingNum: 0
    }
  },
  computed: {
    redMain() {
      if (this.messageData.length > 0) {
        return this.messageData.every(item => item.dataStatus === 0) ? false : true;
      }
      if (this.messageData.length = 0) {
        return false
      }

    }
  },


  mounted() {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const userId = url.searchParams.get('userId');
    if (userId) {
      getToken(userId).then(res => {
        if(res.data){
          this.$Cookie.set('accessToken', res.data.accessToken);
          this.$Cookie.set("userName", res.data.userName);
          this.init()
        }else{
          this.init()
        }
      })
    } else {
      this.init()
    }


  },
  created() {
    // 通用背景色存储，其他页面调用
    // this.$Cookie.set("navBackground", "#0079FE")
    // this.navBackground = JSON.parse(localStorage.getItem('navBackground'))
  },
  methods: {
    async init() {
      const cookies = document.cookie.split('; ')
      this.token = extractAccessTokenValue(cookies)
      const mallInfoId = sessionStorage.getItem("mallInfoId")
      if (mallInfoId !== '0') {
        this.getMallById(mallInfoId)
      } else {
        this.extractLastPathSegment()
      }


      this.getTabName()
      // this.getlist()
      await this.getUserPhone()
      // this.getMessage()
      this.restaurants = this.loadAll();
    },
    changeHover(color) {
      return {
        "--bgColor": color + '7f',
        "--color-hover": color
      }
    },
    extractLastPathSegment() {
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      const codes = this.$Cookie.get('distributor')
      if (codes) {
        this.getInfo()
      } else {
        const lastPart = url.searchParams.get('code');
        if (lastPart) {
          this.$Cookie.set('distributor', lastPart)
          this.getInfo()
        }
      }
    },
    getInfo() {
      const distributor = this.$Cookie.get("distributor")
      getInfo(distributor).then(res => {
        if (res.data) {
          this.routerKey = new Date().getTime()
          if (res.data?.systemConfig?.systemLogoUrl) this.systemConfig.systemLogoUrl = res.data?.systemConfig?.systemLogoUrl
          if (res.data?.systemConfig?.themeColor) this.navBackground = res.data?.systemConfig?.themeColor
          this.$Cookie.set("navBackground", JSON.stringify(res.data?.systemConfig?.themeColor))
          localStorage.setItem("navBackground", JSON.stringify(res.data?.systemConfig?.themeColor))
          this.$Cookie.set('distributorId', res.data.distributorId)
          this.$store.commit("SET_USER_INFO", res.data)
          this.$Cookie.set('sysConfig', JSON.stringify(res.data?.systemConfig))
          if (res.data?.systemConfig?.mallName !== "") this.mallConfig.mallName = res.data?.systemConfig?.mallName
          if (res.data?.systemConfig?.mallLogoUrl !== "") this.mallConfig.mallLogoUrl = res.data?.systemConfig?.mallLogoUrl
        } else {
          this.$alert('请先前往分销商后台配置商城信息', '', {
            confirmButtonText: '确定',
            callback: action => {
            }
          });
        }
      }).catch(err => {

      })
    },
    // 通过id来查 (预览)
    getMallById(mallInfoId) {
      getMallById(mallInfoId).then(res => {
        if (res.data) {
          this.routerKey = new Date().getTime()
          if (res.data?.systemConfig?.systemLogoUrl) this.systemConfig.systemLogoUrl = res.data?.systemConfig?.systemLogoUrl
          if (res.data?.systemConfig?.themeColor) this.navBackground = res.data?.systemConfig?.themeColor
          this.$Cookie.set("navBackground", JSON.stringify(res.data?.systemConfig?.themeColor))
          localStorage.setItem("navBackground", JSON.stringify(res.data?.systemConfig?.themeColor))
          this.$Cookie.set('distributorId', res.data.distributorId)
          this.$store.commit("SET_USER_INFO", res.data)
          if (res.data?.systemConfig?.mallName) this.mallConfig.mallName = res.data?.systemConfig?.mallName
          if (res.data?.systemConfig?.mallLogoUrl) this.mallConfig.mallLogoUrl = res.data?.systemConfig?.mallLogoUrl
        } else {

        }
      }).catch(err => {
        console.log(err);
      })
    },

    // 获取用户手机号
    getUserPhone() {
      this.userLoginPhone = this.$Cookie.get('userName')
      if (this.userLoginPhone) {
        let userArray = {
          phone: this.userLoginPhone,
          distributorId: this.$Cookie.get('distributorId'),
          // access_token:this.$Cookie.get('accessToken')
        }
        getUserInfo(userArray).then((res) => {
          this.$store.commit("SET_PHONE_USER_INFO", res.data)
          this.routerKey = new Date()
          if (res.code === 200) {
            this.$Cookie.set('user_info', JSON.stringify(res.data))

            
            this.tabShow = true
            if (this.token) {
              this.loginName = res.data.nickName
            } else {
              this.loginName = "登录"
            }
            this.avatarImg = res.data.avatarUrl
            this.customId = res.data.customerId
            const data = {
              customId: res.data.customerId,
              //  customId:10085761,
              distributorCompanyId: res.data.distributorCompanyId,
              distributorId: res.data.distributorId
            }
            this.$Cookie.set('distributorCompanyId', res.data.distributorCompanyId)
            getnotice(data).then((res) => {
              this.messageData = res.data || []
              //   if (messageDataTemp && messageDataTemp.length > 0) {
                //   this.redMain = true
                //   this.messageData = messageDataTemp.map(item => {
              //     return {
              //       ...item,
              //       'read': true
              //     }
              //   })
              // }
            })
            setInterval(() => {
              getnotice(data).then((res) => {
                this.messageData = res.data || []
              })
            }, 30000)
            this.jumpShopping()
          }
        })
      }
    },
    loadAll() {
      return [
      ];
    },
    querySearchAsync(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(results);
      }, 3000 * Math.random());
    },
    createStateFilter(queryString) {
      return (state) => {
        return state.value.toLowerCase().indexOf(queryString.toLowerCase()) !== -1;
      };
    },
    handleSelect(item) {
      this.routerKey = new Date()
      var that = this
      that.activeTab = 'shophome'
      let tempParams = {
        marque: this.form.roleName,
        productCategoryId: '',
      }
      let parObj = JSON.stringify(tempParams)
      this.$router.push({
        path: "/website/shophome/productscreening",
        query: {
          type: 'search',
          product: parObj,
        }
      })
    },


    callOur() {
      const loginCheck = this.$Cookie.get('userName')
      if (loginCheck) {
        // window.scrollTo(0, 0)
        this.$router.push(`/website/chat`);
        this.activeTab = 'chat'
        this.$Cookie.set('activeTab', "chat");
      } else {
        this.$message.warning('请先登录')
      }
    },
    /**
     * @Date:2024/03/27
     * @Author:wt
     * @Function:获取消息列表
     * @param:无
     */
    // getMessage() {
    //   console.log(this.datalist,'yyyy');
    //   const data = {

    //   }

    //   let messageDataTemp = [
    //     { title: '入会申请', content: '申请入会申请入会申请入会申请入会申请入会申请入会申请入会申请入会', time: '2024-04-27 14:50' },
    //     { title: '入会申请', content: '申请入会申请入会申请入会申请入会申请入会申请入会申请入会申请入会', time: '2024-03-20 14:58' },
    //     { title: '入会申请', content: '申请入会申请入会申请入会申请入会申请入会申请入会申请入会申请入会', time: '2024-03-08 14:58' },
    //     { title: '入会申请', content: '申请入会申请入会申请入会申请入会申请入会申请入会申请入会申请入会', time: '2024-03-16 14:58' },
    //     { title: '入会申请', content: '申请入会申请入会申请入会申请入会申请入会申请入会申请入会申请入会', time: '2024-03-01 14:58' },
    //     { title: '入会申请', content: '申请入会申请入会申请入会申请入会申请入会申请入会申请入会申请入会', time: '2024-03-02 14:58' },
    //     { title: '入会申请', content: '申请入会申请入会申请入会申请入会申请入会申请入会申请入会申请入会', time: '2024-03-02 14:58' },
    //     { title: '入会申请', content: '申请入会申请入会申请入会申请入会申请入会申请入会申请入会申请入会', time: '2024-03-02 14:58' },
    //     { title: '入会申请', content: '申请入会申请入会申请入会申请入会申请入会申请入会申请入会申请入会', time: '2024-03-02 14:58' }
    //   ]
    //   if (messageDataTemp && messageDataTemp.length > 0) {
    //     this.messageData = messageDataTemp.map(item => {
    //       return {
    //         ...item,
    //         'read': true
    //       }
    //     })
    //   }
    // },
    /**
     * @Date:2024/03/27
     * @Author:wt
     * @Function:字省略
     * @param:文字传参
     */
    getShortenedText(textData) {
      let maxLength = 20
      if (textData.length <= maxLength) {
        return textData;
      } else {
        return textData.substring(0, maxLength - 3) + '...';
      }
    },
    /**
     * @Date:2024/03/25
     * @Author:wt
     * @Function:购物车跳转
     * @param:无
     */
    jumpShopping(type) {
      const loginCheck = this.$Cookie.get('userName')
      if (loginCheck) {
        const userId = JSON.parse(this.$Cookie.get('user_info'))
        const tempParams = { userId: userId.customerId }
        shoppingCar(tempParams).then(res => {
          let queryParmas = []
          if (res.code === 200) {
            if (res.data.length > 0) {
              this.shoppingNum = res.data.length
              // var temp = res.data.map(item => `${item.marque} ${item.quantity}`)
              // queryParmas = JSON.stringify(temp)
              res.data.map(item => {
                let obj = { marque: item.marque, quantity: item.quantity }
                queryParmas.push(obj)
              })
              // queryParmas = res.data.map(item => `${item.marque} ${item.quantity}`).join('\n')
            } else {
              // const temp = [
              //   { marque: 'ABL1A01', quantity: 10 },
              //   { marque: 'ATV-12H018F1', quantity: 10 },
              //   { marque: 'OSMC32N-C10A/1P', quantity: 10 },
              //   { marque: 'TM221C16R', quantity: 10 }, 
              //   { marque: 'OSMC32N-C10A/1P', quantity: 10 },
              // ]
              // queryParmas = JSON.stringify(temp);
            }
            //   this.formattedString = this.aa.map(item => `${item.productMarque} ${item.productNum}`).join('\n')
          }
          if (type === 'jump') {
            this.routerKey = new Date().getTime()
            let obj = {
              product: JSON.stringify(queryParmas),
            }
            this.$router.push({
              path: "/website/quickorder",
              query: obj
            });
            // if (this.activeTab == 'quickorder') {
            //   location.reload()
            // }
            // this.activeTab = 'quickorder'
            // this.$Cookie.set('activeTab', this.activeTab);
          }



        })
        // return false
        // this.$router.push('/website/quickorder');
      } else {
        // this.$message.warning('请先登录') 
      }
    },
    jumpBaojia() {
      const loginCheck = this.$Cookie.get('userName')
      if (loginCheck) {
        // this.$router.push('/website/QuotationRecordv');
        this.$router.push('/website/quickorder');
        this.activeTab = 'quickorder'
        this.$Cookie.set('activeTab', this.activeTab);
      } else {
        this.$message.warning('请先登录')
      }
    },
    // getlist(){
    //   getInfo('e8b27f87').then(res => {
    //     this.$Cookie.set("distributorId", res.data.distributorId);
    //   })
    // },
    getTabName() {
      const nowTab = this.$Cookie.get('activeTab')
      if (nowTab) {
        this.activeTab = nowTab
        this.$router.push(`/website/${nowTab}`);
        this.routerKey = new Date()
      }
    },

    /**
     * date:2024/2/22
     * author:wt
     * function:右上角登录
     * @param:无
     */
    login(type) {
      if (type === 'register') {
        this.$router.push({ path: '/login', query: { from: 'register', type: "mall" } });
      } else if (type === 'login') {
        if (this.loginName == '登录') {
          this.$router.push({ path: '/login', query: { type: "mall" } });
        }
      }

      // this.showFlag = 5
    },
    /**
     * date:2024/2/22
     * author:wt
     * function:右上角安全退出
     * @param:无
     */
    loginout() {
      if (this.loginName !== '登录') {
        userLoginOut().then((res) => {
          if (res.code === 200) {
            this.$message.success('退出成功')
            this.$Cookie.remove('userName');
            this.$Cookie.remove('user_info');
            this.$Cookie.remove('accessToken');
            this.$Cookie.remove('activeTab');
            // localStorage.removeItem('accessToken');
            this.loginName = '登录'
            this.avatarImg = require('@/assets/images/user.png')
            this.tabShow = false
            location.reload()
            this.$router.push('/website/shophome/shop');


          }
        })
      } else {
        return false
      }
    },
    /**
     * Date:2024/2/22
     * Author:wt
     * function:tabs栏跳转
     * @param tab
     * @param event
     */
    handleTabs(tab, event) {
      if (tab.name == "shophome") {
        this.$router.push(`/website/shophome/shop`);
      } else {
        this.$router.push(`/website/${tab.name}`);
      }
      this.activeTab = tab.name;
      // 将选项卡状态保存到本地存储
      this.$Cookie.set('activeTab', this.activeTab);
      this.form.roleName = ''
    },
    /**
     * Date:2024/2/26
     * Author:wt
     * function:搜索产品加跳转列表
     * @param 无
     */
    searchProduct() {
      let item = {
        marque: this.form.roleName,
        productCategoryId: '',
      }
      this.$router.push({
        path: "/website/shophome/productscreening",
        query: {
          product: item
        }
      })
    },
    handleTip() {
      this.tipFlag = !this.tipFlag
    },
    /**
     * Date:2024/03/27
     * Author:wt
     * Function:消息红点处理
     * @param event
     */
    handleMessage(item, event) {
      // 掉接口单个红点
      const id = item.customerNoticeId
      updatered(id).then((res) => {
        if (res.code === 200) {
          this.diaTitle = '消息详情'
          this.infoDetail = item
          this.avatarTip = require('@/assets/images/u40054.png')
          this.messFlag = false
        }
      })
      // item.read = false

    },
    /**
     * Date:2024/03/27
     * Author:wt
     * Function:对号叉号处理
     * @param event
     */
    diaRighthandle() {
      if (this.diaTitle === '消息通知') {

        //调接口全部标记
        // item.read = false
        // this.redMain = false
        const id = item.customId
        updatered(id).then((res) => {
          if (res.code === 200) {
            this.getUserPhone()
          }
        })

      } else if (this.diaTitle === '消息详情') {
        this.messFlag = true
        this.diaTitle = '消息通知'
        this.avatarTip = require('@/assets/images/u40053.png')
      }
    },
    getTimeAgo(timestampString) {
      const timestamp = new Date(timestampString);
      const now = new Date();
      const diff = now.getTime() - timestamp.getTime();
      const minutes = Math.floor(diff / (1000 * 60));
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const weeks = Math.floor(days / 7);

      if (weeks > 0) {
        return `${weeks}周前`;
      } else if (days > 0) {
        return `${days}天前`;
      } else if (hours > 0) {
        return `${hours}小时前`;
      } else {
        return `${minutes}分钟前`;
      }
    }

  },
  // watch: {
  //   $route(to, from) {
  //     console.log(to)
  //     console.log(from,'2')
  //   }
  // },
}
</script>
<style lang="scss">
.website {
  .el-input__inner {
    width: 580px;
    height: 28px !important;
    border-radius: 10px;
  }
}
</style>
<style lang="scss" scoped>
html,
body {
  scroll-behavior: smooth;
}
::v-deep .el-tabs__item.is-active {
  color: var(--color-hover);
}
::v-deep .el-tabs__active-bar {
  background-color: var(--color-hover);
}
::v-deep .el-tabs__item:hover {
  color: var(--color-hover);
}
::v-deep .el-tabs__nav {
  height: 55px;
  align-items: center;
  // border-bottom: 2px solid var(--color-hover);
}
::v-deep .el-icon-search {
  line-height: 29px !important;
}

::v-deep .el-tabs__item {
  font-size: 16px !important;
}

.website {
  height: fit-content;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: #fff;
  border-bottom: 1px solid var(--color-hover);

  .messageBox {
    width: 16%;
    height: 400px;
    position: absolute;
    right: 50px;
    top: 55px;
    border-radius: 5px;
    background: white;
    z-index: 9999;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1),
      0 0 5px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1);
    overflow-y: auto;

    .message_title {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;

      div {
        border-bottom: 1px solid rgba(232, 234, 236);
      }

      div:nth-child(1) {
        width: 85%;
        padding: 10px;
      }

      div:nth-child(2) {
        width: 15%;
        padding-top: 3px;
      }
    }

    .message_table {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      border-bottom: 1px solid rgba(232, 234, 236);
      padding-left: 10px;
      cursor: pointer;

      .mess_left {
        width: 80%;
        height: 40px;

        .redPoint {
          display: inline-block;
          width: 8px;
          height: 8px;
          background: red;
          border-radius: 50%;
        }
      }

      .mess_right {
        width: 20%;
        height: 40px;
        font-size: 10px;
        display: flex;
        align-items: end;
      }
    }
  }
}

.ysnavbar {
  width: 100%;
  height: 45px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  z-index: 999;
  .logo-outer-box {
    width: 168px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
  }
  .logo-outer {
    width: 38px;
    height: 38px;
    background: #ffffff50;
    margin: 0 6px 0 0px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .logo-inside {
      width: 30px;
      height: 30px;
      background: white;
      display: inline-block;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .company-name {
    color: white;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    display: inline-block;
  }

  .nav-help {
    width: 310px;
    height: 53px;
    position: absolute;
    right: 20px;
    line-height: 50px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    .nav-menu {
      list-style-type: none;
      font-size: 15px;
      color: white;
      width: 60%;

      .redMain {
        position: absolute;
        top: 10px;
        right: 2px;
        display: inline-block;
        width: 35%;
        height: 10px;
        background: red;
        border-radius: 50%;
      }

      li {
        float: left;
        cursor: pointer;
        height: 50px;
        margin-right: 20px;
        padding: 3px 8px;
      }
    }

    .custom-dropdown {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 50%;
    }

    .el-dropdown-link {
      display: flex;
      align-items: center;
    }
  }

  .userimg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: inline-block;
    background: #e6f5ff;
    margin-top: 3px;
  }
}

.ys-body {
  width: 1360px;
  margin: 0 auto;

  .body_search {
    width: 100%;
    height: fit-content;

    .logosearch {
      width: 1360px;
      height: 130px;
    }

    .logosearch {
      height: 53px;
      margin: 10px auto;
      display: flex;
      // justify-content: space-between;

      .el-input {
        width: 660px;
        height: 30px;
      }

      .left {
        width: 200px;
        margin-right: 10px;
        height: 100%;
      }

      .right {
        width: calc(100% - 210px);
        height: 53px;
        // background-color: rgba(64, 158, 255, 1);
        display: flex;
        align-items: center;
        justify-content: space-between;

        .r-input {
          margin: auto;
          width: 660px;
          height: 40px;
          display: flex;
          padding: 6px 10px;
          background-color: var(--color-hover);
          border-radius: 5px;

          .in-text {
            color: #fff;
            line-height: 30px;
            font-size: 14px;
            cursor: pointer;
            background-color: var(--color-hover);
          }
        }

        .r-btn {
          width: 160px;
          .el-button {
            background-color: var(--color-hover);
            border: 1px solid var(--color-hover);
          }
        }
      }
    }

    .hambur {
      width: 1360px;
      height: 55px;
      margin: 0 auto;
      background: white;
      display: flex;
      flex-wrap: nowrap;

      .nav_tip {
        width: 200px;
        height: 100%;
        color: white;
        text-align: center;

        p {
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 55px;
          font-size: 17px;
        }
      }

      .nav_hambur {
        width: calc(100% - 200px);
        height: 100%;
        ::v-deep .el-tabs {
          height: 100%;
        }
        .no-login {
          font-size: 16px;
          color: #409eff;
          border-bottom: 2px solid var(--color-hover) !important;
          z-index: 999;
          width: 12%;
          height: 55px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        .router-link-active {
          text-decoration: none;
          color: black;
        }

        a {
          text-decoration: none;
          color: white;
        }
      }
    }
  }
}
::v-deep .el-tabs__nav-wrap::after {
  height: 0;
}
</style>
