<template>
  <div class="detail-ordermanage">
    <div class="body-table">
      <div style="display: flex">
        <div style="width: 2px; height: 15px; background-color: rgba(64, 158, 255); margin-right: 8px"></div>
        订单内容
      </div>
      <div class="table-wrap" style="width: 100%">
        <!-- <div class="right"> -->

        <div class="y-table" style="width: 100%; margin-top: 18px">
          <!-- <el-table ref="expandTable" :data="[orderRow]" max-height="275" tooltip-effect="dark" lazy>
            <el-table-column label="序号" align="center" type="index" width="50" />
            <el-table-column width="150px" label="订单号" align="center" prop="orderNo" />
            <el-table-column label="订单类型" align="center" prop="orderType" />
            <el-table-column label="客户名称" align="center" prop="customerName" />
            <el-table-column label="提交人" align="center" prop="submitterName" />
            <el-table-column label="负责销售" align="center" prop="saleUserName" />
            <el-table-column label="发货模式" align="center" prop="executionMode" />
            <el-table-column label="需货时间" align="center" prop="deliveryDateTime" />
            <el-table-column label="订单状态" align="center" prop="orderStatus" />
            <el-table-column label="处理人" align="center" prop="updateBy" />
            <el-table-column label="订单金额" align="center" prop="orderAmount" />
          </el-table> -->
          <div class="orderInfo">
            <el-form class="order_info_form">
              <div class="colunm">
                <el-form-item label="申请编号:">
                  {{orderRow?.orderNo}}
                </el-form-item>
                <el-form-item label="下单客户:">
                  {{orderRow?.customerName}}
                </el-form-item>
                <el-form-item label="签约方式:">
                  {{orderRow?.signingMethod}}
                </el-form-item>
                <!--
          
                 -->
              </div>
              <div class="colunm">
                <div class="colunm2" style="padding: 0 20px 0px 0 ;">
                  <el-form-item label="申请类型:" style="width:150px">
                    {{orderRow?.orderType}}
                  </el-form-item>
                  <el-form-item label="申请人:" style="width:230px">
                    {{orderRow?.customerName}}
                  </el-form-item>
                </div>
                <el-form-item label="财务规则:">
                  {{orderRow?.financialRules}}
                </el-form-item>
                <el-form-item label="收货地址:">
                  {{orderRow?.financialRules}}
                </el-form-item>

              </div>
              <div class="colunm">
                <el-form-item label="提交时间:">
                  {{forma(orderRow?.updateTime)}}
                </el-form-item>
                <el-form-item label="紧急程度:">
                </el-form-item>
                <el-form-item label="收货时间:">
                  {{forma(orderRow?.deliveryDateTime)}}
                </el-form-item>
              </div>
            </el-form>
          </div>
          <el-table v-loading="loading" :data="productList" :header-cell-style="{
              height: '25px',
              background: '#f2f2f2',
              padding: '0'
            }" :cell-style="{ width: '100%', padding: '0' }" :row-style="{ height: '25px' }">
            <el-table-column label="序号" align="center" type="index" width="50" />
            <el-table-column label="产品型号" align="center" prop="productMarque" />
            <el-table-column label="单位" align="center" prop="unit" />
            <el-table-column label="品牌" align="center" prop="brand" />
            <el-table-column label="品类" align="center" prop="productCategory" />
            <el-table-column label="采购单号" align="center" prop="purchaseOrderNo" />
            <el-table-column label="单价" align="center" prop="benchmarkPrice" width="120"/>
            <el-table-column label="数量" align="center" prop="productNum" width="120"/>
            <el-table-column label="总价" align="center" prop="productAmount" width="120"/>
            <el-table-column label="执行进度" align="center" prop="executeStatus" width="120" />
          </el-table>
          <div class="bottom">
            <span class="total-name">总计</span>
            <div>

              <span class="num">{{ total().totalNum }}</span>
              <span class="total">{{ total().totalPrice }}</span>
            </div>
          </div>
        </div>

        <!-- </div> -->
      </div>
      <div class="super">
        <div style="display: flex">
          <div style="width: 2px; height: 15px; background-color: rgba(64, 158, 255); margin-right: 8px"></div>
          审批流程
        </div>
        <div class="super-content">
          <div v-for="(item, index) in flowItemDTOS" :key="index" class="node-item">
            <div v-if="item.levelNo" class="super-tree" :class="{'active': item.flowItemStatus }">
              <div>{{ item.flowItemName }}</div>
              <div style="display: flex; margin-top: 10px; position: relative">
                <div style="display: flex">
                  <div
                    style="width: 20px; height: 20px; border: 1px solid rgba(64, 158, 255); border-radius: 50%; background-color: #fff">
                    <!-- <img :src="avatar" style="width: 20px; height: 20px;" alt="" /> -->
                  </div>
                  <div style="margin: 0px 10px">{{ item.flowItemUserName }}</div>
                </div>
                <div style="line-height: 25px">
                  <div>{{ item.flowItemRemark }}</div>
                  <div>{{ item.createTime }}</div>
                </div>
                <div style="margin-top: 26px; position: absolute; right: 0px;">审批耗时：{{ item.operTime }} 分钟</div>
              </div>
            </div>
            <div v-else class="super-fen">
              <div class="left">
                <div v-for="(subFlowL, idx) in item.subFlowCg" :key="idx" class="super-left">
                  <div>{{ subFlowL.flowItemName }}</div>
                  <div style="display: flex; position: relative; margin-top: 10px">
                    <div style="display: flex">
                      <div
                        style="width: 20px; height: 20px; border: 1px solid rgba(64, 158, 255); border-radius: 50%; background-color: #fff">
                      </div>
                      <div style="margin: 0px 10px">{{ subFlowL.flowItemUserName }}</div>
                    </div>
                    <div style="line-height: 25px">
                      <div>{{ subFlowL.flowItemRemark }}</div>
                      <div>{{ subFlowL.createTime }}</div>
                    </div>
                    <div style="margin-top: 26px; position: absolute; right: 0px;">审批耗时：{{ subFlowL.operTime }} 分钟</div>
                  </div>
                </div>
              </div>
              <div class="right">
                <div v-for="(subFlowR, idx) in item.subFlowCw" :key="idx" class="super-right">
                  <div>{{ subFlowR.flowItemName }}</div>
                  <div style="display: flex; position: relative;; margin-top: 10px">
                    <div style="display: flex">
                      <div
                        style="width: 20px; height: 20px; border: 1px solid rgba(64, 158, 255); border-radius: 50%; background-color: #fff">
                      </div>
                      <div style="margin: 0px 10px">{{ subFlowR.flowItemUserName }}</div>
                    </div>
                    <div style="line-height: 25px">
                      <div>{{ subFlowR.flowItemRemark }}</div>
                      <div>{{ subFlowR.createTime }}</div>
                    </div>
                    <div style="margin-top: 26px; position: absolute; right: 0px;">审批耗时：{{ subFlowR.operTime }} 分钟</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="super-line" :class="{'active': item.flowItemStatus}">
              <div class="tubiao"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  
<script>
import { queryOrderFLowDetail } from '@/api/orderManages'
import { EXECUTE_STATUS } from '@/utils/const'
import avatar from '@/assets/images/user.png'
import { formatDate } from "@/utils/tools.js"
export default {
  name: "detailordermanage",
  data() {
    return {
      // loading: false,
      // delivery: true,
      // form: {},
      // dataList: [],
      // searchName: '',
      // searchData: {},
      avatar: avatar,
      loading: false,
      delivery: true,
      form: {},
      dataList: [],
      searchName: '',
      flowItemDTOS: [],
      productList: [],
      commonParams: {
      }
    }
  },
  computed: {
    total() {
      return () => {
        let totalNum = 0
        let totalPrice = 0
        this.productList.map(item => {
          if (item.productNum) {
            totalNum = totalNum + item.productNum
          }
          totalPrice = totalPrice + item.productAmount
        })
        return { totalNum, totalPrice }
      }
    },
    forma() {
      return function (a) {
        return formatDate(a)
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {

    getList() {
      let row = JSON.parse(this.$route.query.product)
      this.orderRow = row
      console.log(this.orderRow);
      queryOrderFLowDetail({ orderNo: row.orderNo }).then(response => {
        this.productList = response.data.orderGoodsDetails ? response.data.orderGoodsDetails.map((item) => {
          return {
            ...item,
            executeStatus: _.get(_.find(EXECUTE_STATUS, { value: item.executeStatus }), 'text', '')
          }
        }) : []
        this.flowItemDTOS = response.data.flowItemDTOS
        this.loading = false
      })
    },
    handleSelect(item) {
      console.log(item)
    },
    commit() { },
    rest() { }
  }
}
</script>

  
<style lang="scss">
.detail-ordermanage {
  background-color: #fff;

  .search-top {
    display: flex;
    justify-content: space-between;

    .leball {
      display: flex;
    }
  }

  .el-table .cell {
    padding: 3px 0;
  }

  .el-input--mini .el-input__inner {
    height: 21px;
  }

  .step .el-input-number__increase {
    height: 21px;
  }

  .step .el-input-number__decrease {
    height: 21px;
  }

  .el-input-number--mini {
    width: 90px;
  }

  .actions {
    padding-left: 13px;
  }

  .super {
    padding: 20px 0;
    width: 100%;

    .super-content {
      width: 100%;
      color: #666666;

      .super-tree {
        width: 436px;
        height: 101px;
        border: 1px solid #ededed;
        // margin: 5px auto 0;
        margin: 0 auto;
        padding: 10px;
        border-radius: 10px;
      }

      .super-line {
        width: 2px;
        height: 101px;
        background-color: #ededed;
        margin: 0 auto;
        position: relative;

        .tubiao {
          width: 10px;
          height: 10px;
          position: absolute;
          bottom: -1px;
          left: -6px;
          color: #c0c4cc;
        }
      }
      .super-fen {
        width: 550px;
        height: 500px;
        border: 2px solid #ededed;
        margin: 0 auto;
        border-radius: 5px;
        display: flex;
        .super-left {
          width: 436px;
          height: 101px;
          border: 1px solid #ededed;
          margin-top: 101px;
          margin-left: -218px;
          background-color: #fff;
          padding: 10px;
          border-radius: 10px;
        }
        .right {
          height: 100%;
          margin-left: 100px;
          display: flex;
          margin-top: 100px;
          .super-right {
            width: 436px;
            height: 101px;
            border: 1px solid #ededed;
            background-color: #fff;
            padding: 10px;
            border-radius: 10px;
          }
        }
      }
      .active {
        background-color: rgb(64, 158, 255);
        color: #fff;
      }
      .node-item:last-child .super-line {
        display: none;
      }
    }
  }
  ::v-deep .y-table {
    margin: 10px 0px !important;
  }
  .total {
    display: inline-block;
    width: 120px;
    margin-right: 120px;
    text-align: center;
  }
  .num {
    display: inline-block;
    width: 120px;
  }
  .body-table .table-wrap {
    margin-top: -10px;
  }

  .orderInfo {
    display: flex;
    width: 100%;
    padding: 0 20px;
    .order_info_form {
      width: 100%;
      display: flex;
      .colunm {
        width: 33%;
        justify-content: space-between;
        align-items: center;
        .colunm2{
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
</style>
