import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
// 用Vuex.Store对象用来记录token

const state = {
  // 存储token
  token: "",
  userName: "", // 可选
  userInfo: {},
  phoneUserInfo:{}
}
const getters = {
  getToken(state) {
    return state.token || localStorage.getItem("token") || ""
  }
}
const mutations = {
  // 修改token，并将token存入localStorage
  setToken(state, token) {
    state.token = token
    localStorage.setItem('token', token)
    console.log('store、localstorage保存token成功！')
  },
  delToken(state) {
    state.token = ""
    localStorage.removeItem("token")
  },
  // 可选
  setUserInfo(state, userName) {
    state.userName = userName
  },
  SET_USER_INFO: (state, data) => {
    state.userInfo = data
  },
  SET_PHONE_USER_INFO: (state, data) => {
    state.phoneUserInfo = data
  },
}
const actions = {
}
const store = new Vuex.Store({
  state, // 状态
  mutations, // 包含多个更新state函数的对象
  actions, // 包含多个队形事件回调函数的对象
  getters, // 包含多个getter计算属性函数的对象
  modules: { // 模块化
  }
})

export default store
