<template>
  <div class="callme">
    <div class="call_body">
      <div v-for="(item,key) in contents" :key="key" class="call_main">
        <div class="call_message">
          <ul>
            <li style="margin: 45px 0;font-size: 19px">{{ item.addressName }}</li>
            <li style="margin: 20px 0;color: #7F7F7F">电话：{{ item.phone }}</li>
            <li style="margin: 20px 0;color: #7F7F7F">客服：{{ item.customerService }}</li>
            <li style="margin: 20px 0;color: #7F7F7F">邮箱：{{ item.mailbox }}</li>
            <li style="margin: 20px 0;color: #7F7F7F">邮编：{{ item.zipCode }}</li>
            <li style="margin: 20px 0;color: #7F7F7F">地址：{{ item.address }}</li>
          </ul>
        </div>
        <div class="call_map">
          <div :id="'map' + (key + 1)" style="width: 100%;height: 318px;"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

window._AMapSecurityConfig = {
  securityJsCode: 'bd71bbeaac86b23b0b6a93ce444f2496'
}

import bottomInfo from "@/views/job/bottomInfo.vue";
import AMapLoader from '@amap/amap-jsapi-loader'

export default {
  name: "callme",
  props: { contents: Array },
  components: { bottomInfo, },
  data() {
    return {
      map: null,
      mapTest: [],
      recruit: []
    }
  },
  created() {

  },
  mounted() {
    // this.getList()
    this.initMap()
    console.log(this.contents)
  },
  methods: {
    getList() {

    },

    // AMapLoader.load({
    //   key:"3b29e4b86749385cc510fde6572dfd37",             // 申请好的Web端开发者Key，首次调用 load 时必填
    //   version:"2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
    //   plugins: [
    //     'AMap.ToolBar',
    //     'AMap.Scale',
    //     'AMap.Geolocation',
    //     'AMap.PlaceSearch',
    //     'AMap.AutoComplete',
    //     'AMap.Geocoder',
    //     'AMap.CitySearch'
    //   ],     // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    // }).then((AMap)=>{
    //   this.map = new AMap.Map("maps",{  //设置地图容器id
    //     viewMode:"3D",    //是否为3D地图模式
    //     zoom:11,           //初始化地图级别
    //     center:[108.948273, 34.275896], //初始化地图中心点位置
    //   });
    // }).catch(e=>{
    //   console.log(e);
    // })
    initMap() {
      AMapLoader.load({
        key: "4c151426dc310c9274ac9e0e705a51e5",             // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          'AMap.ToolBar',
          'AMap.Scale',
          'AMap.Geolocation',
          'AMap.PlaceSearch',
          'AMap.AutoComplete',
          'AMap.Geocoder',
          'AMap.CitySearch'
        ],     // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        this.contents.forEach((item, index) => {
          const mapId = `map${index + 1}`;
          this.mapTest.push(new AMap.Map(mapId, {
            viewMode: "3D",
            zoom: 11,
            center: [108.948273, 34.275896],
          }));
        })
        this.point()
      }).catch(e => {
        console.log(e);
      })
    },
    /**
     * Date:2024/03/12
     * Author:wt
     * Function:高德坐标渲染
     * @param:无
     */
    point() {
      this.contents.forEach((content, index) => {

        const longitude = content?.longitude;
        const latitude = content?.latitude;
        if (longitude && latitude) {

          const mapIndex = index;

          const mapInstance = this.mapTest[mapIndex];
          mapInstance.setZoomAndCenter(10, [longitude, latitude]);

          const infoWindow = new AMap.InfoWindow({
            offset: new AMap.Pixel(20, -25)
          });
          const marker = new AMap.Marker({
            position: [longitude, latitude],
            icon: require('@/assets/images/u306.svg'), // 使用相对路径引用SVG图片
            map: mapInstance,
          });
          AMap.plugin('AMap.Geocoder', function () {
            const geocoder = new AMap.Geocoder();
            // 根据坐标点获取地址信息
            geocoder.getAddress([longitude, latitude], function (status, result) {
              if (status === 'complete' && result.info === 'OK') {
                const address = result.regeocode.formattedAddress;

                // 在信息窗体中展示地址信息
                infoWindow.setContent(address);
                infoWindow.open(mapInstance, marker.getPosition());
              } else {
                // 地址解析失败
                infoWindow.setContent('地址解析失败');
              }
              mapInstance.setFitView();
            });
          });
        }

      });
    },
  },

  destroyed() {
    // console.log(this.mapTest)
    // this.map.destroy();
    // this.map = null;
    // this.mapModule = null;
    this.mapTest.forEach((map) => {
      map.destroy(); // 销毁地图实例
    });
    this.mapTest = []; // 清空地图实例数组
  },
}
</script>

<style lang="scss">
.callme {
  margin: 50px 0 0 0;

  .call_body {
    width: 100%;
    padding: 50px;

    .call_main {
      width: 95%;
      height: 320px;
      border: 1px solid #dcdfe6;
      display: flex;
      flex-wrap: nowrap;
      margin: 0 auto;
      margin-bottom: 50px;

      .call_message {
        width: 35%;
        height: 318px;
        background: #d7d7d7;

        ul {
          list-style-type: none;

          li {
            padding-left: 80px;
          }
        }
      }

      .call_map {
        width: 65%;
        height: 318px;
      }

      #maps {
        width: 100%;
        height: 318px;
      }
    }
  }
}
</style>
