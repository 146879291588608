<template>
  <div class="forget">
    <el-form ref="queryParams" :model="queryParams" :rules="loginRules" class="login-form">
      <h3 style="text-align: center;font-size: 20px;margin-bottom: 35px">重置密码</h3>
      <el-form-item prop="phone">
        <el-input
            v-model="queryParams.phone"
            placeholder="请输入手机号"
            auto-complete="off"
            class="input-with-select"
        >
          <!-- <el-select v-model="select" slot="prepend" placeholder="请选择">
            <el-option label="+86" value="1"></el-option>
          </el-select> -->
        </el-input>
      </el-form-item>
      <el-form-item
          prop="code"
      >
        <el-input
            v-model="queryParams.code"
            placeholder="验证码"
        >
          <div slot="suffix">
            <span class="space">|</span>
            <span
                class="input-btn w90"
                @click="sendCode"
            >{{ buttonmsg }}</span>
          </div>
        </el-input>
      </el-form-item>
      <el-form-item prop="firstPassWord">
        <el-input type="password" v-model="queryParams.firstPassWord" placeholder="请输入新密码" show-password></el-input>
      </el-form-item>
      <el-form-item prop="pwd">
        <el-input type="password" v-model="queryParams.pwd" placeholder="确认密码" @change="affirmPass" show-password></el-input>
      </el-form-item>
      <el-form-item style="width: 100%;">
        <el-button
            :loading="loading"
            size="medium"
            type="primary"
            style="width:100%;margin-top: 25px;"
            @click.native.prevent="fastRegister">
          确定重置
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {getCodePass, getModPass, getPhoneCode,} from "@/utils/api";
var timer
export default {
  name: "forget",
  data() {
    return {
      buttonmsg: '获取短信验证码',
      passFlag:false,
      loading:false,
      queryParams:{
        phone:'',
        code:'',
        pwd:'',
        firstPassWord:'',
      },
      loginRules: {
        phone: [
          { required: true, trigger: 'blur', message: '请输入您的手机号' }
        ],
        firstPassWord: [
          { required: true, trigger: 'blur', message: '请输入您的密码' }
        ],
        pwd: [
          { required: true, trigger: 'blur', message: '请输入您的密码' }
        ],
        // code: [{ required: true, trigger: 'blur', message: '请输入验证码' }]
      },
    }
  },
  methods: {
    // 获取验证码
    sendCode() {
      if (this.queryParams.phone === '') return
      // 验证码类型(source来源 1、司机端认证 2、用户实名)
      getPhoneCode({ phone: this.queryParams.phone, type:3,distributorId: this.$Cookie.get('distributorId'),})
      this.$message.success('验证码获取成功')
      let time = 60
      timer = setInterval(() => {
        time--
        if (time === 0) {
          clearInterval(timer)
          this.buttonmsg = '重新获取'
          return
        }
        // this.flag = true
        this.buttonmsg = time + 's'
      }, 1000)
    },
    /**
     * Date:2024/3/18
     * Author:wt
     * Function:密码对比确认
     * @param:无
     */
    affirmPass(){
      if(this.queryParams.firstPassWord !== this.queryParams.pwd){
        this.$message.error('两次输入密码不一致')
        this.passFlag = true
        return false
      }else{
        this.passFlag = false
      }
    },
    /**
     * Date:2024/3/18
     * Author:wt
     * Function:确定重置密码+验证码校验
     * @param:无
     */
    fastRegister(){
      if(this.queryParams.code !== '' && this.passFlag === false){
        this.queryParams.distributorId =  this.$Cookie.get('distributorId')
        getModPass(this.queryParams).then((res)=>{
          if(res.code === 200){
            this.$message.success('重置成功')
            this.$emit('handlePath',1)
            this.queryParams={
              phone:'',
              code:'',
              pwd:'',
              firstPassWord:'',
            }
          }else{
            this.$message.error(res.msg)
          }
        })
      }
    },
  },

  watch:{}
}
</script>

<style lang="scss" scoped>
.forget {
  //::v-deep .el-input--suffix{
  //  width: 100% !important;
  //}
  .login-form {
    background: #f5f7fa;
    width: 30%;
    height: 500px;
    box-shadow: 0px 0px 6px rgb(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 50px;
    margin: 130px auto;
    .el-form-item{
      margin-bottom: 25px !important;
    }
    //.el-form-item{
    //  margin-bottom: 32px;
    //  .el-form-item__content{
    //    line-height: 36px;
    //  }
    //  .el-input__inner{
    //    height: 36px;
    //  }
    //}
    //.el-input {
    //  height: 36px;
    //  input {
    //    height: 36px;
    //  }
    //}
    //.input-btn{
    //  font-size: 12px;
    //  padding: 0 6px 0 14px;
    //  color: #909399;
    //  display: inline-block;
    //  &.w90{
    //    width: 120px;
    //    text-align: center;
    //  }
    //}
    //.el-select .el-input {
    //  width: 76px;
    //}
    //.input-with-select .el-input-group__prepend {
    //  background-color: #fff;
    //}
  }
}
</style>
