import axios from 'axios'
import path from 'path'
import md5 from 'js-md5'
import { getToken, getUserInfo } from '@/utils/auth'

import {baseURL} from "@/utils/request";
// eslint-disable-next-line no-async-promise-executor
export default (file, options) => new Promise(async (resolve, reject) => {
  /*
  * STEP1 获取上传策略 Upload strategy 部分
  * 必填 options.resourceType 资源类型(1图片 2合同 3文件)
  * 必填 options.distributorId 资源类型(分销商的ID传, 因斯后台0)
  * 必填 sourceFileName 不带文件后缀的文件名
  * 必填 module 文件目录名称,会自定创建 例如 ys_manage | fx-manage
  * 必填 createBy 创建者  userInfo.nickName
  * 必填  分销商公司ID，因斯后台传0 userInfo.distributorCompanyId
  */
  let strategy = null
  function extractAccessTokenValue (cookies) {
    let accessTokenCookie = cookies.find(cookie => cookie.startsWith('accessToken='))
    if (accessTokenCookie) {
      let accessToken = accessTokenCookie.split('=')[1]
      return accessToken
    } else {
      return null
    }
  }
  const cookies = document.cookie.split('; ')
  let token = extractAccessTokenValue(cookies)
  const STRATEGY = {
    resourceType: options.resourceType,
    distributorId: getUserInfo().distributorId || 1,
    module: options.module,
    bucket: process.env.VUE_APP_BUCKET,
    sourceFileName: path.basename(file.name, path.extname(file.name)),
    calibackParam: null,
    createBy: getUserInfo().nickName || 'Leo',
    distributorCompanyId: getUserInfo().distributorCompanyId || 200
  }
  try {
    const { status, data } = await axios({
      url: baseURL + '/resource/upload/get/policy',
      method: 'post',
      data: STRATEGY,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
    if (status === 200 && data.code === 200) {
      strategy = data.data
    } else {
      console.error('err: 获取上传策略接口错误')
      reject({
        status: 'err'
      })
    }
  } catch (error) {
    console.error('err: 获取上传策略接口错误')
    reject({
      status: 'err'
    })
  }
  // 根据上传策略直接上传Oss
  const getFileName = (file) => {
    return md5(`${file.name}-${new Date().getTime()}-${parseInt(Math.random() * 10000)}`) + path.extname(file.name)
  }
  const fileName = getFileName(file)
  const UPLAOD = {
    key: strategy.dir,
    policy: strategy.policy,
    OSSAccessKeyId: strategy.accessid,
    success_action_status: 200,
    callback: strategy.callback,
    signature: strategy.signature,
    'x:source_type': strategy['x:source_type'],
    'x:filename': fileName,
    'x:callback_param': strategy['x:callback_param'] ? strategy['x:callback_param'] : '',
    'x:source_file_name': strategy['x:source_file_name'] ? strategy['x:source_file_name'] : '',
    'x:distributor_id': strategy['x:distributor_id'] ? strategy['x:distributor_id'] : '',
    'x:distributor_company_id': strategy['x:distributor_company_id'] ? strategy['x:distributor_company_id'] : '',
    'x:create_by': strategy['x:create_by'] ? strategy['x:create_by'] : '',
    // eslint-disable-next-line no-dupe-keys
    'x:source_type': strategy['x:source_type'] ? strategy['x:source_type'] : '',
    // eslint-disable-next-line no-dupe-keys
    'x:source_file_name': strategy['x:source_file_name'] ? strategy['x:source_file_name'] : '',
    // eslint-disable-next-line no-dupe-keys
    'x:callback_param': strategy['x:callback_param'] ? strategy['x:callback_param'] : null,
    file: new File([file], fileName, { type: file.type }),
    name: fileName
  }
  const formData = new FormData()
  for (var n in UPLAOD) {
    formData.append(n, UPLAOD[n])
  }
  const cancelToken = axios.CancelToken
  const cancel = cancelToken.source()
  try {
    axios({
      url: strategy.host,
      method: 'post',
      data: formData,
      cancelToken: cancel.token,
      onUploadProgress (progressEvent) {
        if ('progress' in options) {
          options.progress(progressEvent)
        }
      }
    }).then((res) => {
      if (res.status === 200 && res.data.code === 200) {
        resolve({
          status: 'success',
          cancel,
          data: {
            resourceUrl: res.data.resourceUrl,
            sourceFileName: res.data.sourceFileName,
            resourceId: res.data.resourceId
          }
        })
      } else {
        console.error('err: Oss直传接口错误')
        reject({
          status: 'err'
        })
      }
    })
  } catch (error) {
    console.error('err: Oss直传接口错误')
    reject({
      status: 'err'
    })
  }
})

