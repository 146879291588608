<template>
  <div class="brand-box">
    <div class="bread">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/website/shophome/shop' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>代理品牌</el-breadcrumb-item>
      </el-breadcrumb>

    </div>
    <div class="brand_box">
      <div class="brand_card" v-for="(item,index) in noticelist" :key="index">
          <img class="brand_card_img" :src="item.brandImgUrl" alt="">
          <p class="brand_card_text">{{item.brandName}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getBrand,
} from '@/api/mallindex'
export default {
  name: "brand",
  components: {},
  data() {
    return {
      noticelist: []
    }
  },
  created() {
    var that = this;
    that.getBrand()
  },
  methods: {
    getBrand() {
      var that = this
      let distributorId = this.$Cookie.get('distributorId')
      let distributorCompanyId =this.$Cookie.get('distributorId')
      let data = {
        distributorId: distributorId,
        distributorCompanyId: distributorCompanyId || 0
      }
      getBrand(data).then(res => {
        this.noticelist = res.data
      })
    }
  },
  computed: {},
}
</script>
<style  lang="scss" scoped>
.brand-box {
  width: 100%;
  background: #f5f7fa;
  border-radius: 10px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  .bread {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
  }
  .brand_box {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    padding: 0px 20px;

    .brand_card {
      width: 158px;
      height: 130px;
      background: #ffffff;
      // border: 1px solid #f5f7fa;
      margin-right: 3px;
      margin-bottom: 3px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .brand_card_img {
        text-align: center;
        width: 136px;
        height: 75px;
      }
      .brand_card_text{
        font-size: 13px;
        margin-top: 12px;
        text-align: center;
      }
    }
  }
}
</style>