<template>
  <div class="navbar">
    <!--  顶部导航栏 -->
    <div :style="{'background':navBackground}" class="ysnavbar">
      <div class="logo-outer nav">
        <div class="logo-inside" @click="showFlag=1">

          <img alt="logo" :src="systemConfig?.systemLogoUrl?systemConfig?.systemLogoUrl :require('@/assets/images/logo.jpg')">
        </div>
      </div>
      <p class="company-name" @click="showFlag=1">杭州因斯科技有限公司</p>

    </div>
  </div>
</template>

<script>

export default {
  name: 'navBar',
  components: {},
  data() {
    return {
      //  背景色
      navBackground: '#0079FE',
      systemConfig: {}
    }
  },
  mounted() {

  },
  created() {
    this.systemConfig = JSON.parse(this.$Cookie.get('sysConfig'))
    this.navBackground = this.systemConfig?.themeColor
    // 通用背景色存储，其他页面调用
    // localStorage.setItem("navBackground", JSON.stringify("#0079FE"))
  },
  methods: {

    callOur() {
      this.showFlag = 2
      window.scrollTo(0, 0)
    },


  },
  computed: {},
}
</script>

<style lang="scss" scoped>
html,
body {
  scroll-behavior: smooth;
}

.navbar {
  // height: 220px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: #fff;
}

.ysnavbar {
  width: 100%;
  height: 45px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  z-index: 999;

  .logo-outer {
    width: 38px;
    height: 38px;
    background: #ffffff31;
    margin: 0 0 0 20px;
    border-radius: 50%;
    
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .logo-inside {
      
      width: 30px;
      height: 30px;
      background: white;
      display: inline-block;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .company-name {
    margin: 0 80px 0 10px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    display: inline-block;
  }

  .nav-help {
    width: 270px;
    height: 53px;
    position: absolute;
    right: 20px;
    line-height: 50px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    .nav-menu {
      list-style-type: none;
      font-size: 15px;
      color: white;
      width: 60%;

      li {
        float: left;
        cursor: pointer;
        height: 50px;
        margin-right: 20px;
        padding: 3px 8px;
      }

      li:hover {
        background: rgb(58, 142, 230);
      }
    }

    .custom-dropdown {
      display: flex;
      align-items: center;
      justify-content: right;
      width: 35%;
    }

    .el-dropdown-link {
      display: flex;
      align-items: center;
    }
  }

  .userimg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: inline-block;
    background: #e6f5ff;
    margin-left: 5px;
    padding: 0 0 0 3px;
    margin-top: 3px;
  }
}
</style>
./navBar.vue