<template>
  <div class="recruit">
    <div class="rec_body">
      <div class="rec_main" v-for="(item,key) in invites" :key="key">
        <div>
          <div style="width: 100%;height:150px;text-align: center;padding-top: 50px">
            <p style="font-size: 16px">{{item.jobName}}</p>
            <p style="margin-top: 15px;font-size: 14px;color: #606266">({{item.jobLocation}})</p>
          </div>

        </div>
        <div>
          <div style="width: 90%;height:100%;margin: 0 auto;padding: 20px;overflow: auto;">
            <p style="margin: 5px;color: #909399;font-size: 15px" v-html="renderDesc(item.jobDesc)"></p>
          </div>
        </div>
        <div>
          <div style="width: 70%;height:210px;margin: 0 auto;padding-top: 60px;color: #909399;">
            <p style="margin-bottom: 10px;font-size: 15px">简历发送至：</p>
            <p style="font-size: 14px">{{item.mailbox}}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- <bottom-info></bottom-info> -->
  </div>
</template>

<script>
import bottomInfo from "@/views/job/bottomInfo.vue";
export default {
  name: "recruit",
  props: {invites: Array},
  components: {bottomInfo,},
  data() {
    return {
      recruit: [
        {
          address:'西安',
          jobTitle:'技术工程师',
          requested:[
            {name:'精通PHP，熟悉常见的PHP开发框架和技术，两年以上经验；'},
            {name:'熟悉MYSQL的开发，熟练掌握LAMP/LNMP,缓存等性能优化；'},
            {name:'有良好的代码习惯，要求精确清晰。命名规范。'}
          ],
          eMAil:'12345@163.com'
        },
        {
          address:'成都',
          jobTitle:'业务销售',
          requested:[
            {name:'精通PHP，熟悉常见的PHP开发框架和技术，两年以上经验；'},
            {name:'熟悉MYSQL的开发，熟练掌握LAMP/LNMP,缓存等性能优化；'},
            {name:'有良好的代码习惯，要求精确清晰。命名规范。'}
          ],
          eMAil:'12345@163.com'
        }
      ]
    }
  },
  mounted(){
  },
  methods:{
    /**
     * date:2024/2/23
     * author:wt
     * function:识别文字的换行和缩进
     * @param:desc
     */
    renderDesc(desc) {
      return desc.replace(/\n/g, '<br>');
    }
  },
}
</script>

<style lang="scss">
.recruit{
  background: #ffffff;
  .rec_body{
    width: 100%;
    height: 100vh;
    padding: 50px;
    .rec_main{
      width: 95%;
      height: 280px;
      border: 1px solid #DCDFE6;
      margin: 0 auto;
      margin-bottom: 50px;
      display: flex;
      flex-wrap: nowrap;
      padding-top: 30px;
    }
    .rec_main>div:nth-child(1){
      width: 20%;
      height: 220px;
      padding: 25px;
      border-right: 1px solid #DCDFE6;
    }
    .rec_main>div:nth-child(2){
      width: 60%;
      height: 220px;
    }
    .rec_main>div:nth-child(3){
      width: 20%;
      height: 220px;
      border-left: 1px solid #DCDFE6;
    }
  }
}
</style>
