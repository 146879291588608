<template>
  <div class="chat">
    <!--    <div class="userTip">{{centerMessage[0].name}}</div>-->
    <div class="userTip">{{ companyName }}</div>
    <div class="chat_box">
      <div class="hgroups" ref="hgroups">
        <div :class="item.position === 'right' ? 'user' : 'system'" v-for="(item,index) in centerMessage"
          :key="item.customerMessageId">
          <!--            客户-->
          <div class="contents" v-if=" item.position === 'right'">
            <div style="height: 65px;display: flex;flex-direction: column;align-items: end;">
              <div
                style="margin-right: 10px;color: #A5A6A8;font-size: 13px;min-width: 100px;text-align: right;">
                {{item.nickName?item.nickName:'暂无姓名'}}</div>
              <div class="textstyle">{{ item.content }}</div>
            </div>
            <div class="avatar" style="margin-right:5px;"><img style="width: 100%;height:100%;"
                :src="item.avatarUrl?item.avatarUrl:default_img" alt></div>
          </div>
          <!--            销售-->
          <div class="content-left" v-if="item.position === 'left'">
            <div class="avatar" style="margin-left:3px;">
              <img style="width: 100%;height:100%;" :src="item.avatarUrl?item.avatarUrl:default_img" alt>
            </div>
            <div style="height: 65px;display: flex;flex-direction: column;align-items: start;">
              <div style="margin-left: 10px;color: #A5A6A8;font-size: 13px;min-width: 100px;text-align: left;">
                {{item.replyPeopleName?item.replyPeopleName:'暂无姓名'}}</div>
              <div class="textstyle">{{ item.content }}</div>
            </div>
          </div>

        </div>
      </div>
      <!-- 底部 -->
      <div class="footers">
        <el-input resize="none" class="ipt" placeholder="请输入内容" @keyup.enter.native="sending" v-model="textarea" />
        <el-button type="primary" @click="sending" class="footers_btn">发送
          (Enter一键发送)
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { chatList, sendChat } from "@/api/chat";

export default {
  name: "chat",
  data() {
    return {
      default_img: require("../../assets/images/standby.jpg"),
      textarea: '',
      nickName:'',
      companyName: '山河智能装备股份有限公司',
      centerMessage: [
      ]
    }
  },
  mounted() {
    this.companyName = this.$store.state.phoneUserInfo?.customerName
    this.getList()
  },
  methods: {
    /**
     * @Date:2024/3/28
     * @Author:wt
     * @Function:初始化消息列表
     * @param: 无
     */
    getList() {
      let user = this.$Cookie.get('user_info')
      const tempUserInfo = JSON.parse(user)
      this.nickName = tempUserInfo?.nickName
      let searchData = {
        distributorId: this.$Cookie.get('distributorId'),
        distributorCompanyId: this.$Cookie.get('distributorCompanyId'),
        customId: tempUserInfo.customerId
      }
      chatList(searchData).then(res => {
        // console.log(res,"aaaaaaaaaaa");
        if (res.code === 200) {
          // console.log(res.data)
          this.centerMessage = res.data.customerMessageResult
          this.centerMessage?.map(item=>{
            if( item.replyPeopleId === tempUserInfo.userId){
              item.position = 'right'
            } else {
              item.position = 'left'
            }
            if(item.senderType == 1 && !item.replyPeopleName){
              item.replyPeopleName =  item.nickName
            }
          })
          this.$nextTick(() => {
            this.scrollToBottom()
          })
        }
      })
    },
    /**
     * Date:2024/03/15
     * Author:wt
     * Function: 发送消息
     * @param:无
     */
    sending() {
      if (this.textarea === '') {
        this.$message.warning('请输入内容再发送')
      } else {

        let user = this.$Cookie.get('user_info')
        const tempUserInfo = JSON.parse(user)
        let searchData = {
          distributorId: this.$Cookie.get('distributorId'),
          distributorCompanyId: this.$Cookie.get('distributorCompanyId'),
          customId: tempUserInfo.customerId,
          content: this.textarea,
        }
        sendChat(searchData).then((res) => {
          if (res.code === 200) {
            // console.log(res.data)
            this.getList()
            this.$nextTick(() => {
              this.scrollToBottom()
            })
          }
        })
        this.textarea = ''
      }
    },
    /**
     * Date:2024/03/15
     * Author:wt
     * Function: 判断是否触底
     * @param:无
     */
    scrollToBottom() {
      const container = this.$refs.hgroups;
      // 滚动到容器底部
      container.scrollTop = container.scrollHeight;

    },
  },
}
</script>

<style scoped lang="scss">
.chat {
  ::v-deep .el-button:hover {
    background-color: rgb(53, 58, 76) !important;
  }
  padding: 10px 0;
  .userTip {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    padding: 10px;
    border-radius: 15px 15px 0 0;
    border: 2px solid #6b7081;
    border-bottom: 1px solid #a368ce;
  }
  .chat_box {
    width: 80%;
    height: calc(100vh - 285px);
    border: 2px solid #6b7081;
    border-top: none;
    border-radius: 0 0 15px 15px;
    margin: 0 auto;
    position: relative;
    padding: 0 0px 30px 0px;
  }
  .hgroups {
    /* 内容区样式*/
    padding: 10px 30px;
    height: calc(100vh - 335px);
    /* background-color: azure; */
    overflow: auto;
    /*聊天内容过多时自动添加滚动条*/
  }
  .system {
    margin-top: 20px; 
    /* 系统说话样式*/
    float: left;
    clear: both;
    /* display: flex; */
    /* align-items: center; */
  }
  .content-left {
    float: left;
    display: flex;
    margin-top: 3px;
    align-items: center;
  }

  .avatar {
    width: 50px;
    height: 50px;
    border: 1px solid #ededed;
    border-radius: 50%;
    text-align: center;
    overflow: hidden;
  }

  .textstyle {
    /* 气泡框绿色背景样式*/
    padding: 10px;
    background-color: white;
    border-radius: 0 10px 10px 10px;
    font-size: 14px;
    margin-left: 10px;
    max-width: 500px;
    word-wrap: normal;
    /* 说话太长自动换行*/
    line-height: 24px;
    color: #303133;
  }

  .footers {
    /* 底部区样式*/
    display: flex;
    flex-wrap: nowrap;
    /* 控制底部框框和按钮在一排 横着显示的*/
    height: 50px;
    position: absolute;
    bottom: 0;
    padding: 10px 30px;
    width: 100%;
    display: flex;
    align-items: center;
    .ipt {
      ::v-deep .el-input__inner{
        border-radius: 5px 0 0 5px;
      }
    }
    .footers_btn {
      height: 40px;
      background: #9599a3;
      border: none;
      border-radius:0 4px 4px 0;
    }
  }
  .user {
    margin-top: 20px;
    /* 用户说话样式*/
    float: right;
    /* 用户说话自动靠右*/
    clear: both;
    /* 用户说话左边不能有东西*/
    /* display: flex; */
    /* 头像和说话并排显示*/
    align-items: center;

    /* 头像在说话高度中间对齐*/
    .textstyle {
      /* 气泡框绿色背景样式*/
      padding: 10px;
      background-color: #8dfa69;
      border-radius: 10px 0 10px 10px;
      margin-right: 10px;
      font-size: 14px;
      color: #303133;
      max-width: 500px;
      word-wrap: normal;
      /* 说话太长自动换行*/
      line-height: 24px;
      text-align: right;
    }
    .contents {
      float: right;
      display: flex;
      margin-top: 3px;
      align-items: center;
    }

    .ipt {
      margin-right: 5px;
      /* 底部区框框和按钮中间的间隙*/
    }
  }
}
</style>
