import request from '@/utils/request'
// 客户下拉搜索
export function filterByOrderType(data) {
  return request({
    url: '/order/queryCustomerList',
    method: 'post',
    data
  })
}
// 提交订单
export function commitOrder(data) {
  return request({
    url: '/order/commitOrder',
    method: 'post',
    data
  })
}
// 客户用印--执行订单
export function executeOrder(data) {
  return request({
    url: '/order/implementOrder',
    method: 'post',
    data
  })
}
// 客户用印--上传合同
export function uploadContract(data) {
  return request({
    url: '/order/uploadContract',
    method: 'post',
    data
  })
}
// 取消订单
export function cancelOrder(data) {
  return request({
    url: '/order/cancelOrder',
    method: 'post',
    data
  })
}
// 生成报价单
export function generateOuotation(data) {
  return request({
    url: '/order/generateQuotation',
    method: 'post',
    data
  })
}
// 删除报价单
export function deleteQuotation(data) {
  return request({
    url: '/order/deleteQuotation',
    method: 'post',
    data
  })
}
// 客户用印列表查询
export function queryCustomerSignList(data) {
  return request({
    url: '/order/queryOrderList',
    method: 'post',
    data: data
  })
}
// 客户用印 -- 查看合同
export function queryContarctDetails(data) {
  return request({
    url: '/order/queryContractDetail',
    method: 'post',
    data: data
  })
}
// 客户用印 -- 下载合同
export function loadContarct(data) {
  return request({
    url: '/order/loadContract',
    method: 'post',
    data: data
  })
}
// 客户用印 -- 选中锁库
export function lockOrderDetail(data) {
  return request({
    url: '/order/lockOrderDetail',
    method: 'post',
    data: data
  })
}
// 客户用印 -- 解锁锁库
export function unLockOrderDetail(data) {
  return request({
    url: '/order/unLockOrderDetail',
    method: 'post',
    data: data
  })
}
// 订单排发 -- 选中发货
export function deliverProduct(data) {
  return request({
    url: '/order/deliverProduct',
    method: 'post',
    data: data
  })
}
// 报价记录列表查询
export function queryQuotationRecordList(data) {
  return request({
    url: '/order/queryQuotationList',
    method: 'post',
    data
  })
}
// 订单排发列表查询
export function queryOrderSecheduleList(data) {
  return request({
    url: '/order/queryImplementOrderList',
    method: 'post',
    data
  })
}
// 订单排发-产品列表查询
export function queryImplementOrderDetail(data) {
  return request({
    url: '/order/queryImplementOrderDetail',
    method: 'post',
    data
  })
}
// 客户签收列表查询
export function customerSignRecive(data) {
  return request({
    url: '/order/querySignOrder',
    method: 'post',
    data
  })
}
// 订单记录列表查询
export function queryOrderRecord(data) {
  return request({
    url: '/order/queryOrderRecord',
    method: 'post',
    data
  })
}
// 发货记录列表查询
export function queryDeliveryRecordList(data) {
  return request({
    url: '/order/queryDeliveryRecordList',
    method: 'post',
    data
  })
}
// 发货记录列表 -- 取消发货
export function cancelDeliveryRecord(data) {
  return request({
    url: '/order/cancelDeliveryRecord',
    method: 'post',
    data
  })
}
// 退货记录列表查询
export function queryReturnOrderList(data) {
  return request({
    url: '/order/queryReturnOrderList',
    method: 'post',
    data
  })
}

// 退货记录列表查询
export function queryReturnOrderDetailList(data) {
  return request({
    url: '/order/queryReturnOrderDetailList',
    method: 'post',
    data
  })
}

// 订单排发-产品列表查询
export function customerSignReciveDetail(data) {
  return request({
    url: '/order/querySignOrderDetail',
    method: 'post',
    data
  })
}

// 订单修改
export function updateOrder(data) {
  return request({
    url: '/order/updateOrder',
    method: 'post',
    data
  })
}
// 客户用印/报价记录/订单记录产品列表
export function queryOrderProductDetails(data) {
  return request({
    url: '/order/queryOrderDetail',
    method: 'post',
    data
  })
}
// 产品查询地址
export function queryProductList(data) {
  return request({
    url: '/order/queryProductList',
    method: 'post',
    data
  })
}
// 订单记录 -- 申请发票
export function applyForInvoice(data) {
  return request({
    url: '/order/applyForInvoice',
    method: 'post',
    data
  })
}
// 订单记录 -- 退货
export function addReturnOrder(data) {
  return request({
    url: '/order/addReturnOrder',
    method: 'post',
    data
  })
}
// 客户签收 -- 上传签收单
export function uploadSignOrder(data) {
  return request({
    url: '/order/uploadSignOrder',
    method: 'post',
    data
  })
}
// 获取退货仓库
export function queryWarehouseList(data) {
  return request({
    url: '/order/queryWarehouseList',
    method: 'post',
    data
  })
}
// 新增收货地址
export function addReciveAddress(data) {
  return request({
    url: '/customer/createCustomerAddress',
    method: 'post',
    data
  })
}
// 通过客户id获取收货地址列表
export function getReciveAddressList(data) {
  return request({
    url: '/customer/getAddressByCustomerId',
    method: 'post',
    data
  })
}
