
const formatMoney = function(value, decimalPlaces=2) {
  if (!value) return '--'
  let handleNum = parseFloat(value)
  let isToFixed = handleNum.toString().includes('.') && handleNum.toString().split('.')[1].length > 2
  if (isToFixed) {
    handleNum = handleNum.toFixed(decimalPlaces)
  }
  return String(handleNum).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
const install = function(Vue) {
  Vue.filter('formatMoney', formatMoney)
}
export default install



